import * as Yup from 'yup';
import moment from 'moment-timezone';
import { initDevice, addDevice } from '../../../../services/companiesServices';

const generateRandomNumber = () => {
  const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
  return randomNumber.toString();
};

export const DEVICE_TYPES = {
  lock: {
    codelocks: {
      label: 'Codelocks',
      description: 'Codelocks devices must be initialised before they are added to the system. When you click the "Create Device" button below, it will initialise the device and you will receive the instructions in an alert message. You must enter the sequence provided immediately to set the time correctly!',
      validationSchema: Yup.object().shape({
        module: Yup.object().shape({
          Model: Yup.string().required('Model is required'),
          Timezone: Yup.string().required('Timezone is required'),
          MasterCode: Yup.string()
            .min(8, 'Master Code must be 8 numbers long')
            .max(8, 'Master Code must be 8 numbers long')
            .required('Master Code is required'),
        }),
        friendlyName: Yup.string().required('You must give the lock a name!'),
        actions: Yup.array().min(1, 'You must provide at least 1 action'),
      }),
      initialValues: {
        module: {
          Model: '',
          Timezone: '',
          MasterCode: '',
        },
        friendlyName: '',
        actions: ['GENERATE_KEYCODE'],
      },
      formFields: [
        {
          type: 'object',
          name: 'module',
          fields: [
            {
              label: 'Model',
              name: 'Model',
              type: 'select',
              options: [
                { label: 'KL1060', value: 'KL1060' },
                { label: 'KL1060C2', value: 'KL1060C2' },
                { label: 'KL1060G3', value: 'KL1060G3' },
                { label: 'CL5000N', value: 'CL5000N' },
                { label: 'K3CONNECT', value: 'K3CONNECT' },
              ],
              required: true,
            },
            {
              label: 'Timezone',
              name: 'Timezone',
              type: 'select',
              options: moment.tz.names().map((tz) => ({ label: tz, value: tz })),
              required: true,
            },
            {
              label: 'Master Code',
              name: 'MasterCode',
              type: 'text',
              required: true,
            },
          ],
        },
        {
          label: 'Lock Name',
          name: 'friendlyName',
          type: 'text',
          required: true,
        },
      ],
      onSubmit: async (companyId, values) => {
        const { data: initData, status } = await initDevice(companyId, {
          provider: 'codelocks',
          module: values.module,
        });

        if (status !== 200) throw new Error('Failed to initialise device!');

        alert(`Please enter the following information to initialise the device immediately:\n\nEnter the following sequence on the lock:\n${initData.InitSeq}\n\nThe hyphens represent a light on the device, so no input is required for them.`);

        const data = {
          provider: 'codelocks',
          module: {
            ...values.module,
            Initialisation: initData,
          },
          friendlyName: values.friendlyName,
          deviceIdentifier: initData.RandomID,
          device_nodes: [
            {
              nodeIdentifier: initData.TimeCode,
              type: 'LOCK',
              friendlyName: values.friendlyName,
              isSmart: false,
            },
          ],
          actions: values.actions,
        };

        const { status: addStatus } = await addDevice(companyId, data);
        if (addStatus !== 200) throw new Error('Failed to add device!');
      },
      initialise: (formik, company) => {
        formik.setFieldValue('module.Timezone', company.timezone);
        formik.setFieldValue('module.MasterCode', generateRandomNumber());
      },
    },
    salto: {
      label: 'Salto',
      description: null,
      validationSchema: Yup.object().shape({
        provider: Yup.string().required('Provider is required'),
        actions: Yup.array().min(1, 'You must provide at least 1 action'),
        friendlyName: Yup.string().required('Site Name is required'),
        deviceIdentifier: Yup.string().required('Device Identifier is required'),
        nodeIdentifier: Yup.string().required('Lock Identifier is required'),
        nodeFriendlyName: Yup.string().required('Lock Name is required'),
      }),
      initialValues: {
        provider: 'salto',
        actions: ['GENERATE_KEYCODE', 'HEALTH_CHECK'],
        module: {
          IdentityServerUrl: 'https://identity.saltoks.com',
          APIUrl: 'https://connect.my-clay.com',
        },
        friendlyName: '',
        deviceIdentifier: '',
        nodeIdentifier: '',
        nodeFriendlyName: '',
      },
      formFields: [
        {
          label: 'Site Name',
          name: 'friendlyName',
          type: 'text',
          required: true,
        },
        {
          label: 'IQ Identifier',
          name: 'deviceIdentifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Lock Identifier',
          name: 'nodeIdentifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Lock Name',
          name: 'nodeFriendlyName',
          type: 'text',
          required: true,
        },
      ],
      onSubmit: async (companyId, values) => {
        const data = {
          ...values,
          device_nodes: [{
            type: 'LOCK',
            nodeIdentifier: values.nodeIdentifier,
            friendlyName: values.nodeFriendlyName,
            isSmart: true,
          }],
        };

        delete data.nodeIdentifier;
        delete data.nodeFriendlyName;

        const { status } = await addDevice(companyId, data);
        if (status !== 200) throw new Error('Failed to add device!');
      },
    },
  },
  light: {
    tasmota: {
      label: 'Tasmota',
      description: null,
      validationSchema: Yup.object().shape({
        provider: Yup.string().required('Provider is required'),
        actions: Yup.array().min(1, 'You must provide at least 1 action'),
        deviceIdentifier: Yup.string().required('Device Identifier is required'),
        friendlyName: Yup.string().required('Friendly Name is required'),
        device_nodes: Yup.array().of(Yup.object().shape({
          nodeIdentifier: Yup.string().required('Node Identifier is required'),
          type: Yup.string().required('Node Type is required'),
          friendlyName: Yup.string().required('Node Name is required'),
        })).min(1, 'You must provide at least 1 device node'),
      }),
      initialValues: {
        provider: 'tasmota',
        actions: ['ON', 'OFF', 'TOGGLE', 'HEALTH_CHECK'],
        deviceIdentifier: '',
        friendlyName: '',
        device_nodes: [{
          nodeIdentifier: '',
          type: 'LIGHTING',
          friendlyName: '',
          isSmart: true,
        }],
      },
      formFields: [
        {
          label: 'Device Identifier',
          name: 'deviceIdentifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Friendly Name',
          name: 'friendlyName',
          type: 'text',
          required: true,
        },
        {
          type: 'array',
          label: 'Device Node',
          name: 'device_nodes',
          fields: [
            {
              label: 'Node Identifier',
              name: 'nodeIdentifier',
              type: 'text',
              required: true,
            },
            {
              label: 'Node Name',
              name: 'friendlyName',
              type: 'text',
              required: true,
            },
          ],
          addItem: (formik) => {
            formik.setFieldValue('device_nodes', [
              ...formik.values.device_nodes,
              {
                nodeIdentifier: '',
                type: 'LIGHTING',
                friendlyName: '',
                isSmart: true,
              },
            ]);
          },
          removeItem: (formik, index) => {
            formik.setFieldValue(
              'device_nodes',
              formik.values.device_nodes.filter((_, idx) => idx !== index),
            );
          },
        },
      ],
      onSubmit: async (companyId, values) => {
        const { status } = await addDevice(companyId, values);
        if (status !== 200) throw new Error('Failed to add device!');
      },
    },
    salto: {
      label: 'Salto',
      description: null,
      validationSchema: Yup.object().shape({
        provider: Yup.string().required('Provider is required'),
        actions: Yup.array().min(1, 'You must provide at least 1 action'),
        friendlyName: Yup.string().required('Site Name is required'),
        deviceIdentifier: Yup.string().required('Device Identifier is required'),
        nodeIdentifier: Yup.string().required('Lock Identifier is required'),
        nodeFriendlyName: Yup.string().required('Lock Name is required'),
      }),
      initialValues: {
        provider: 'salto',
        actions: ['ON', 'OFF', 'TOGGLE', 'HEALTH_CHECK'],
        module: {
          IdentityServerUrl: 'https://identity.saltoks.com',
          APIUrl: 'https://connect.my-clay.com',
        },
        friendlyName: '',
        deviceIdentifier: '',
        nodeIdentifier: '',
        nodeFriendlyName: '',
      },
      formFields: [
        {
          label: 'Site Name',
          name: 'friendlyName',
          type: 'text',
          required: true,
        },
        {
          label: 'IQ Identifier',
          name: 'deviceIdentifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Lock Identifier',
          name: 'nodeIdentifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Lock Name',
          name: 'nodeFriendlyName',
          type: 'text',
          required: true,
        },
      ],
      onSubmit: async (companyId, values) => {
        const data = {
          ...values,
          device_nodes: [{
            type: 'LIGHTING',
            nodeIdentifier: values.nodeIdentifier,
            friendlyName: values.nodeFriendlyName,
            isSmart: true,
          }],
        };

        delete data.nodeIdentifier;
        delete data.nodeFriendlyName;

        const { status } = await addDevice(companyId, data);
        if (status !== 200) throw new Error('Failed to add device!');
      },
    },
  },
  terminal: {
    stripe: {
      label: 'Stripe',
      description: null,
      validationSchema: Yup.object().shape({
        identifier: Yup.string().required('You must provide a terminal identifier!'),
        friendlyName: Yup.string().required('You must give the terminal a name!'),
      }),
      initialValues: {
        identifier: '',
        friendlyName: '',
      },
      formFields: [
        {
          label: 'Terminal Identifier',
          name: 'identifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Terminal Name',
          name: 'friendlyName',
          type: 'text',
          required: true,
        },
      ],
      onSubmit: async (companyId, values) => {
        const data = {
          provider: 'stripe',
          deviceIdentifier: values.identifier,
          friendlyName: values.friendlyName,
          device_nodes: [
            {
              nodeIdentifier: values.identifier,
              type: 'TERMINAL',
              friendlyName: values.friendlyName,
              isSmart: false,
            },
          ],
        };

        const { status } = await addDevice(companyId, data);
        if (status !== 200) throw new Error('Failed to add device!');
      },
    },
  },
};
