import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TextField from '@material-ui/core/TextField';
import { getUsers } from '../../../reducers/usersReducer';

const userPropShape = ({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

const parseUsers = (users) => users.map((user) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  const {
    email, teamName, phone, dialCode,
  } = user;
  const mergedPhone = dialCode && phone ? `+${dialCode}${phone}` : '';
  let label = `${fullName} ${email} ${mergedPhone || ''}`;
  if (teamName !== '' && teamName !== null) { label = `${label} (${teamName})`; }
  return ({
    ...user,
    label,
  });
});

const PreviousBookerUserSearch = ({ user, updateUserDetails, disableClearable }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [term, setTerm] = useState(null);
  const { isLoading } = useSelector((state) => state.users);
  const [noUserFound, setNoUserFound] = useState(false);

  const doGetUsers = (page, searchTerm) => dispatch(getUsers(page, searchTerm));

  const fireOffSearch = useCallback(async (searchTerm) => {
    if (searchTerm) {
      setTerm(searchTerm);
      await doGetUsers(0, searchTerm);
      if (users.length === 0) {
        setNoUserFound(true);
      }
    }
  }, [users]);

  const searchAPIDebounced = AwesomeDebouncePromise(fireOffSearch, 800);

  const handleUserSearch = (value) => {
    searchAPIDebounced(value);
  };

  let noOptionsText = 'Start typing to search';
  if (term && term !== '') {
    if (isLoading) {
      noOptionsText = 'Loading...';
    } else if (noUserFound) {
      noOptionsText = 'No user found';
    }
  }

  return (
    <div style={{ textAlign: 'center', margin: 'auto', maxWidth: 400 }}>
      <Typography gutterBottom type="subheading">
        Search for an existing customer here:
      </Typography>
      <Autocomplete
        className="auto-complete-combo"
        options={parseUsers(users)}
        clearIcon
        loading={isLoading}
        noOptionsText={noOptionsText}
        getOptionLabel={(option) => option.label}
        style={{ width: 300, margin: 'auto' }}
        value={user.id && parseUsers([user])[0]}
        onChange={(event, option) => updateUserDetails({ ...option })}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              defaultValue={term}
              label="Select Customer"
              variant="outlined"
              fullWidth
              onChange={(event) => handleUserSearch(event.target.value)}
            />
          </>
        )}
      />
    </div>
  );
};

PreviousBookerUserSearch.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(userPropShape)).isRequired,
  user: PropTypes.shape(userPropShape).isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
};

PreviousBookerUserSearch.defaultProps = {
  disableClearable: true,
};

export default PreviousBookerUserSearch;
