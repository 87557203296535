/* eslint-disable no-case-declarations */
export const COMPANY_ADDONS_RETRIEVAL_REQUESTED = 'COMPANY_ADDONS_RETRIEVAL_REQUESTED';
export const ADDONS_RETRIEVAL_SUCCEEDED = 'ADDONS_RETRIEVAL_SUCCEEDED';

export const CREATE_FACILITY_ADDONS_REQUESTED = 'CREATE_FACILITY_ADDONS_REQUESTED';
export const REMOVE_FACILITY_ADDONS_REQUESTED = 'REMOVE_FACILITY_ADDONS_REQUESTED';

export const FACILITY_ADDONS_REQUESTED = 'FACILITY_ADDONS_REQUESTED';
export const UPDATE_COMPANY_ADDONS = 'UPDATE_COMPANY_ADDONS';
export const CREATE_COMPANY_ADDON = 'CREATE_COMPANY_ADDON';

export const ADD_TO_SELECTED_ADDONS = 'ADD_TO_SELECTED_ADDONS';
export const REMOVE_FROM_SELECTED_ADDONS = 'REMOVE_FROM_SELECTED_ADDONS';
export const RESET_SELECTED_ADDONS = 'RESET_SELECTED_ADDONS';

export const UPDATE_ADDON_STORE = 'UPDATE_ADDON_STORE';
export const RESET_ADDON_STORE = 'RESET_ADDON_STORE';

export const UPDATE_ADDON_REQUESTED = 'UPDATE_ADDON_REQUESTED';
export const UPDATE_ADDON_SUCCEEDED = 'UPDATE_ADDON_SUCCEEDED';
export const UPDATE_ADDON_FAILED = 'UPDATE_ADDON_FAILED';

export const DELETE_ADDON_REQUESTED = 'DELETE_ADDON_REQUESTED';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_COMPANY_CATEGORIES = 'UPDATE_COMPANY_CATEGORIES';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CREATE_STOCK_TRANSACTION = 'CREATE_STOCK_TRANSACTION';

export const GET_ADDON = 'GET_ADDON';
export const CREATE_ADDONS_RESERVATION_REQUESTED = 'CREATE_ADDONS_RESERVATION_REQUESTED';
export const CREATE_ADDONS_RESERVATION_SUCCEEDED = 'CREATE_ADDONS_RESERVATION_SUCCEEDED';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const FETCH_POS_ORDERS = 'FETCH_POS_ORDERS';
export const POS_ORDERS_RETRIEVAL_SUCCEEDED = 'POS_ORDERS_RETRIEVAL_SUCCEEDED';
export const UPDATE_POS_ORDER = 'UPDATE_POS_ORDER';
export const UPDATE_POS_ORDER_TOTAL = 'UPDATE_POS_ORDER_TOTAL';

const initialState = {
  companyAddons: [],
  addon: {
    name: '',
  },
  addonStore: {
    name: '',
    unlimited: false,
    facilities: [],
    events: [],
  },
  categories: [],
  selectedAddons: [],
  posOrders: [],
  posOrder: [],
  posTotal: 0,
  reservation: null,
};

export function requestFacilityAddons(facilityId) {
  return { type: FACILITY_ADDONS_REQUESTED, facilityId };
}

export function updatePOSOrder(order) {
  return { type: UPDATE_POS_ORDER, order };
}

export function updatePOSTotal(total) {
  return { type: UPDATE_POS_ORDER_TOTAL, total };
}

export function deleteAddon(addonId) {
  return { type: DELETE_ADDON_REQUESTED, addonId };
}

export function createStockTransaction(stockTransaction) {
  return { type: CREATE_STOCK_TRANSACTION, stockTransaction };
}

export function getAddon(addonId) {
  return { type: GET_ADDON, addonId };
}

export function updateCompanyAddons(addons) {
  return { type: UPDATE_COMPANY_ADDONS, addons };
}

export function createAddon(addon) {
  return { type: CREATE_COMPANY_ADDON, addon };
}

export function createCategory(category) {
  return { type: CREATE_CATEGORY, category };
}

export function updateCompanyCategories(categories) {
  return { type: UPDATE_COMPANY_CATEGORIES, categories };
}

export function deleteCategory(categoryId) {
  return { type: DELETE_CATEGORY, categoryId };
}

export function fetchOrders(reportType = 'ORDER_HISTORY', selectedDates = null) {
  return { type: FETCH_POS_ORDERS, reportType, selectedDates };
}
export function succeedPOSOrderRetrieval(orders) {
  return { type: POS_ORDERS_RETRIEVAL_SUCCEEDED, orders };
}

export function editProduct(addonId, values) {
  return { type: EDIT_PRODUCT, addonId, values };
}

export const requestAddonsRetrieval = () => (
  { type: COMPANY_ADDONS_RETRIEVAL_REQUESTED }
);

export const succeedAddonsRetrieval = (addons) => (
  { type: ADDONS_RETRIEVAL_SUCCEEDED, addons }
);

export const requestFacilityAddonsCreation = (facilityAddons) => (
  { type: CREATE_FACILITY_ADDONS_REQUESTED, facilityAddons }
);

export const requestFacilityAddonsRemoval = (facilityAddons) => (
  { type: REMOVE_FACILITY_ADDONS_REQUESTED, facilityAddons }
);

export function getCompanyAddons(facilityId, eventId, includeSubAddons = false) {
  return {
    type: FACILITY_ADDONS_REQUESTED, facilityId, eventId, includeSubAddons,
  };
}

export function createAddonReservation(
  items, user = null, paymentStatus = null, isIgnoringStock = false,
) {
  return {
    type: CREATE_ADDONS_RESERVATION_REQUESTED, items, user, paymentStatus, isIgnoringStock,
  };
}

export function succeedAddonReservationCreation(reservation) {
  return { type: CREATE_ADDONS_RESERVATION_SUCCEEDED, reservation };
}

export function addToSelectedAddons(addonId) {
  return { type: ADD_TO_SELECTED_ADDONS, addonId };
}

export function removeFromSelectedAddons(addonId) {
  return { type: REMOVE_FROM_SELECTED_ADDONS, addonId };
}
export function resetSelectedAddons() {
  return { type: RESET_SELECTED_ADDONS };
}

export function updateAddonStore(addonProperty) {
  return { type: UPDATE_ADDON_STORE, addonProperty };
}
export function resetAddonStore() {
  return { type: RESET_ADDON_STORE };
}
export function requestAddonUpdate() {
  return { type: UPDATE_ADDON_REQUESTED };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDONS_RETRIEVAL_SUCCEEDED:
      return { ...state, companyAddons: action.addons };
    case POS_ORDERS_RETRIEVAL_SUCCEEDED:
      return { ...state, posOrders: action.orders };
    case UPDATE_COMPANY_ADDONS:
      return { ...state, addons: action.addons };
    case UPDATE_COMPANY_CATEGORIES:
      return { ...state, categories: action.categories };
    case RESET_SELECTED_ADDONS:
      return { ...state, selectedAddons: [] };
    case RESET_ADDON_STORE:
      return { ...state, addonStore: initialState.addonStore };
    case ADD_TO_SELECTED_ADDONS:
      state.selectedAddons.push(action.addonId);
      return { ...state, selectedAddons: [...state.selectedAddons] };
    case UPDATE_POS_ORDER:
      return { ...state, posOrder: action.order };
    case CREATE_ADDONS_RESERVATION_SUCCEEDED:
      return { ...state, reservation: action.reservation };
    case UPDATE_POS_ORDER_TOTAL:
      return { ...state, posTotal: action.total };
    case REMOVE_FROM_SELECTED_ADDONS:
      const index = state.selectedAddons.indexOf(action.addonId);
      if (index > -1) {
        state.selectedAddons.splice(index, 1);
      }
      return { ...state, selectedAddons: [...state.selectedAddons] };
    case UPDATE_ADDON_STORE:
      return { ...state, addonStore: { ...state.addonStore, ...action.addonProperty } };
    default:
      return state;
  }
};
