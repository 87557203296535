/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PricingTable from './PricingTable';
import * as companyActions from '../../reducers/companiesReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import * as eventActions from '../../reducers/eventsReducer';
import 'rc-time-picker/assets/index.css';
import * as pricingAction from '../../reducers/pricingReducer';
import ToggleButtons from '../../components/ToggleButtons';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';

const PricingPage = ({
  requestPricePackagesRetrieval,
  requestFacilitiesRetrieval,
  requestEventsRetrieval,
  getCompanyInfo,
  getMemberships,
  facilities,
  companies,
  pricePackages,
  pricingErrorMessages,
}) => {
  const [pricePackagesFilter, setPricePackagesFilter] = useState('ACTIVE');
  const [pricePackagesFiltered, setPricePackagesFiltered] = useState([]);

  const displayButtonsData = [{
    buttonTitle: 'Active Pricing',
    buttonValue: 'ACTIVE',
  },
  {
    buttonTitle: 'Expired Pricing',
    buttonValue: 'EXPIRED',
  },
  {
    buttonTitle: 'Future Pricing',
    buttonValue: 'FUTURE',
  }];

  useEffect(() => {
    requestFacilitiesRetrieval();
    requestEventsRetrieval();
    requestPricePackagesRetrieval();
    getCompanyInfo();
    getMemberships();
  }, [requestFacilitiesRetrieval,
    requestEventsRetrieval, requestPricePackagesRetrieval, getCompanyInfo, getMemberships]);

  useEffect(() => {
    if (pricePackages && pricePackagesFilter === 'ACTIVE') {
      setPricePackagesFiltered(pricePackages.filter(
        (pricePackage) => moment(pricePackage.startDate).isAfter(new Date())
        || moment(pricePackage.endDate).isAfter(new Date())
        || pricePackage.startDate === null || pricePackage.endDate === null,
      ));
    } else if (pricePackages && pricePackagesFilter === 'EXPIRED') {
      setPricePackagesFiltered(pricePackages.filter(
        (pricePackage) => pricePackage.endDate && moment(pricePackage.endDate).isBefore(new Date()),
      ));
    } else if (pricePackages && pricePackagesFilter === 'FUTURE') {
      setPricePackagesFiltered(pricePackages.filter(
        (pricePackage) => moment(pricePackage.startDate).isAfter(new Date()),
      ));
    } else {
      setPricePackagesFiltered(pricePackages);
    }
  }, [pricePackages, pricePackagesFilter]);

  return (
    <div>
      <div style={{ backgroundColor: '#fff' }} className="App" />
      <br />
      <PitchbookingUserOnly>
        <div className="table-section">

          <ToggleButtons
            buttonsData={displayButtonsData}
            changeOption={(option) => setPricePackagesFilter(option)}
            value={pricePackagesFilter}
          />
        </div>
      </PitchbookingUserOnly>
      <PricingTable
        pricePackages={pricePackagesFiltered}
        pricingErrorMessages={pricingErrorMessages}
        facilities={facilities.companyFacilities}
        companyMemberships={companies.memberships}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    companies, pricing, facilities,
  } = state;

  const { pricePackages, pricingErrorMessages } = pricing;
  return {
    companies,
    pricePackages,
    pricingErrorMessages,
    facilities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getMemberships: (priceGroup) => dispatch(
    companyActions.requestCompanyMembershipRetrieval(priceGroup),
  ),
  requestPricePackagesRetrieval: () => dispatch(pricingAction.requestPricePackagesRetrieval()),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  requestEventsRetrieval: () => dispatch(eventActions.getEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
