export * from './timetable.utils';
export * from './omitFormProperties';
export * from './isValidUUID';

/**
 * Function that immutably removes properties from an object
 * @param {Object} obj - Object you want the keys remove from
 * @param {string[]} keys - The name of the keys you want to remove
 * @returns {Object} - A new object with the keys removed
 *
 * @example
 * const obj = { name: 'John', age: 25, weight: 150 };
 * const newObj = omitProperties(obj, 'age', 'weight');
 * console.log(newObj); // { name: 'John' }
 */
export const omitProperties = (obj, ...keys) => {
  const keySet = new Set(keys);

  return Object.keys(obj).reduce((acc, key) => {
    if (!keySet.has(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

/**
 * Function that immutably picks properties from an object
 * @param {Object} obj - Object you want the keys to pick from
 * @param {string[]} keys - The name of the keys you want to pick
 * @returns {Object} - A new object with only the keys picked
 *
 * @example
 * const obj = { name: 'John', age: 25, weight: 150 };
 * const newObj = pickProperties(obj, 'name', 'age');
 * console.log(newObj); // { name: 'John', age: 25 }
 */
export const pickProperties = (obj, ...keys) => {
  const keySet = new Set(keys);

  return Object.keys(obj).reduce((acc, key) => {
    if (keySet.has(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
