/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@material-ui/core';
import * as siteActions from '../../../../reducers/sitesReducer';
import PBButton from '../../../../components/Button';
import ClipboardCopy from '../../../../components/ClipboardCopy';

const EditSiteDialog = ({ site }) => {
  const dispatch = useDispatch();
  const { siteStore, isPowerleague } = useSelector((state) => ({
    siteStore: state.sites.siteStore,
    isPowerleague: state.companies.companyInfo.isPowerleague,
  }));

  const [siteEditDialogOpen, setSiteEditDialogOpen] = useState(false);

  const updateSiteStore = (state) => dispatch(
    siteActions.updateSiteStore(state),
  );

  const handleClickOpen = () => {
    setSiteEditDialogOpen(!siteEditDialogOpen);
  };

  const handleRequestClose = () => {
    setSiteEditDialogOpen(!siteEditDialogOpen);
    dispatch(siteActions.resetSiteStore());
  };

  const handleSave = () => {
    dispatch(siteActions.requestSiteDetailsUpdate(site.id));
    handleRequestClose();
  };

  return (
    <div>
      <div>
        <PBButton
          onClick={handleClickOpen}
          variant="contained"
          color="secondary"
          buttonRole="editSites"
          title="Edit Site details"
        />
      </div>
      <Dialog
        open={siteEditDialogOpen}
        onBackdropClick={handleRequestClose}
        onEscapeKeyDown={handleRequestClose}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Edit Site details</DialogTitle>
        </div>
        <DialogContent>
          <TextField
            id="outlined-basic"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Name"
            variant="outlined"
            defaultValue={site.name || null}
            value={siteStore.name || null}
            onChange={(event) => updateSiteStore(
              { name: event.target.value },
            )}
          />
          <TextField
            id="outlined-basic"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Address Line 1"
            variant="outlined"
            defaultValue={site.addressLine1 || null}
            value={siteStore.addressLine1 || null}
            onChange={(event) => updateSiteStore(
              { addressLine1: event.target.value },
            )}
          />
          <TextField
            id="outlined-basic"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Address Line 2"
            variant="outlined"
            defaultValue={site.addressLine2 || null}
            value={siteStore.addressLine2 || null}
            onChange={(event) => updateSiteStore(
              { addressLine2: event.target.value },
            )}
          />
          <TextField
            id="outlined-basic"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Postcode"
            variant="outlined"
            defaultValue={site.postcode || null}
            value={siteStore.postcode || null}
            onChange={(event) => updateSiteStore(
              { postcode: event.target.value },
            )}
          />
          {isPowerleague && (
          <>
            <TextField
              id="outlined-basic"
              style={{ marginBottom: '10px', width: '100%' }}
              label="Site Phone"
              variant="outlined"
              defaultValue={site.phone || null}
              value={siteStore.phone || null}
              onChange={(event) => updateSiteStore(
                { phone: event.target.value },
              )}
            />
            <TextField
              id="outlined-basic"
              style={{ marginBottom: '10px', width: '100%' }}
              label="Site Email"
              variant="outlined"
              defaultValue={site.email || null}
              value={siteStore.email || null}
              onChange={(event) => updateSiteStore(
                { email: event.target.value },
              )}
            />
          </>
          )}
          <TextField
            id="outlined-basic"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Phone Number"
            variant="outlined"
            defaultValue={site.phone || null}
            value={siteStore.phone || null}
            onChange={(event) => updateSiteStore(
              { phone: event.target.value },
            )}
          />
          <TextField
            id="siteDescription"
            style={{ marginBottom: '10px', width: '100%' }}
            label="Description"
            variant="outlined"
            type="textarea"
            margin="normal"
            multiline
            rows="3"
            defaultValue={site.description || null}
            value={siteStore.description || null}
            onChange={(event) => updateSiteStore(
              { description: event.target.value },
            )}
          />
          {site.mapURL && (
          <>
            Map URL
            <div style={{ width: '100%' }}>
              <a href={site.mapURL}>
                {site.mapURL}
              </a>
            </div>
          </>
          )}
          {site.logoUrl && (
          <>
            Logo URL
            <div style={{ width: '100%' }}>
              <a href={site.logoUrl} target="_blank" rel="noreferrer">
                {site.logoUrl}
              </a>
            </div>
          </>
          )}
          <div>
            <Typography variant="caption"> Site ID: </Typography>
            <ClipboardCopy copyText={site.id} />
          </div>
        </DialogContent>
        <DialogActions>
          <PBButton onClick={handleRequestClose} variant="contained" color="secondary">
            Cancel
          </PBButton>
          <PBButton onClick={handleSave} variant="contained" color="primary">
            Save
          </PBButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSiteDialog;
