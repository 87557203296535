export const contentfulToolTipIDs = [
  {
    name: 'subscriptionsStatusKeyToolTip',
    contentfulID: '6VL16jdEngBDUCzvZPdMHj',
  },
  {
    name: 'subscriptionsTableToolTip',
    contentfulID: '5xrilGjLc0eQeNGolGQx2u',
  },
  {
    name: 'calendarOverallToolTip',
    contentfulID: '5cqXdaYGk3ZDj4AiZp64hW',
  },
  {
    name: 'subscriptionNotesToolTip',
    contentfulID: '4KXnH8FoMx5gl5hxLt4TFB',
  },
  {
    name: 'subscriptionViewToolTip',
    contentfulID: '7y7tcDWYjNtoHCCDmXkgfA',
  },
  {
    name: 'payoutChargeIdTooltip',
    contentfulID: '6FMFa1Zvlityoq9a00KkMn',
  },
  {
    name: 'subscriptionsToolTip',
    contentfulID: '3rlKp198ib7lRli8ODL22L',
  },
  {
    name: 'bookingsTableOverallToolTip',
    contentfulID: '5YrrQ5KrZlLCKe89zwSzcT',
  },
  {
    name: 'todayDashboardTabToolTip',
    contentfulID: '62zPe2RpDgfhTTdzAPHbKb',
  },
  {
    name: 'analyticsDashboardTabToolTip',
    contentfulID: '3WQxszZWs98G0w02l2as6q',
  },
  {
    name: 'membersTabToolTip',
    contentfulID: '22kVZ5AgzZpYLYHPAfDzDO',
  },
  {
    name: 'priorityAccessTabToolTip',
    contentfulID: '6Qls1dCcA45eaHCw1xslPa',
  },
  {
    name: 'eventsTabToolTip',
    contentfulID: '1dbLQo6pfJX6YtUDoSDNt9',
  },
  {
    name: 'exclusionsToolTip',
    contentfulID: '1X4BVSDb58w4LCleP1k28g',
  },
  {
    name: 'pricingTabToolTip',
    contentfulID: '5RcAC8yyV4d6BK3GuWqDzJ',
  },
  {
    name: 'locksTabToolTip',
    contentfulID: '7krZIiriWPnaYDHIui8kCC',
  },
  {
    name: 'selfServiceToolTip',
    contentfulID: '2scMFpqaSvt7HCymaz4u9P',
  },
];

// eslint-disable-next-line max-len
export const contentfulToolTipNames = Object.fromEntries(new Map(contentfulToolTipIDs.map((e) => ([e.name, e.name]))));

export function getToolTipID(name) {
  const templateObject = contentfulToolTipIDs.find((e) => e.name === name);
  const { contentfulID } = templateObject;
  return contentfulID;
}
