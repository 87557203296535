import React from 'react';
import moment from 'moment';
import TimetableInitialiser from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableInitialiser';
import TimetableSelectedTimeslots from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableSelectedTimeslots';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { requestFacilityRetrieval, updateSelectedSubFacility } from '../../reducers/facilitiesReducer';
import CompanyFacilityPicker from '../CompanyFacilityPicker';
import { combineTimeslots } from '../../utils';
import { toggleEditBookingDialog } from '../../reducers/bookingsReducer';

const ChangeAllocation = ({
  updateFacility, subscription, priceForSlots, currencySym, date,
}) => {
  const dispatch = useDispatch();
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility)
  || subscription?.accessRestriction?.facilities[0];
  const selectedSubfacility = useSelector((state) => state.facilities.selectedSubFacility);
  const slots = useSelector((state) => state.timetable.selectedTimeslots);

  React.useEffect(() => {
    dispatch(toggleEditBookingDialog());

    return () => {
      dispatch(toggleEditBookingDialog());
    };
  }, []);
  const isMobile = window.innerWidth < 768;
  return (
    <>
      <CompanyFacilityPicker
        updateSelectedFacility={(e) => dispatch(requestFacilityRetrieval(e.id))}
        updateSelectedSubFacility={(e) => dispatch(updateSelectedSubFacility(e))}
       // siteId={selectedFacility?.facilityTree?.siteId}
      />
      <>
        <div className="reservation-creation-timetable">
          <div className="reservation-creation-timetable-picker">
            {selectedFacility && (
            <TimetableInitialiser
              facilityInformation={{
                facilityId: selectedFacility.id,
                subFacilityId: selectedSubfacility?.id,
                companyId: selectedFacility.companyId,
                previousAccessRestrictionId: subscription?.accessRestrictionId,
                previousSlot: subscription?.slot ? moment(subscription?.slot).format('YYYY-MM-DD') : null,
              }}
              mobile={isMobile}
              manager
              blockBooking
              searchDate={date || moment().format('YYYY-MM-DD')}
            />
            )}

          </div>
          <div className="reservation-creation-timetable-basket">
            <TimetableSelectedTimeslots combineSlots />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
          {priceForSlots && (
          <Button
            variant="contained"
            color="primary"
            disabled={slots.length <= 0 || !selectedFacility}
            onClick={() => {
              let timeslots = slots;

              if (selectedFacility?.combineSlots) {
                timeslots = combineTimeslots(timeslots);
              }

              updateFacility(selectedFacility, timeslots, priceForSlots);
            }}
          >
            Update Slot and price
            {' '}
            {`(${currencySym}${priceForSlots})`}
          </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={slots.length <= 0 || !selectedFacility}
            onClick={() => {
              let timeslots = slots;

              if (selectedFacility?.combineSlots) {
                timeslots = combineTimeslots(timeslots);
              }

              updateFacility(selectedFacility, timeslots);
            }}
          >
            Update Slot
          </Button>
        </div>
      </>
    </>
  );
};

ChangeAllocation.propTypes = {
  updateFacility: PropTypes.func.isRequired,
  subscription: PropTypes.shape,
  priceForSlots: PropTypes.string,
  currencySym: PropTypes.string,
  date: PropTypes.string,
};

ChangeAllocation.defaultProps = {
  subscription: null,
  priceForSlots: null,
  currencySym: null,
  date: null,
};

export default ChangeAllocation;
