import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { FormControlLabel } from '@material-ui/core';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import BasicTooltip from '../../../../../components/BasicToolTip';
import FacilityAndSubFacilityPicker from '../../../../facilities/components/FacilityAndSubFacilityPicker';
import MenuHeading from '../../../../../components/MenuHeading';
import lang from '../lang';
import GoogleMapsAutoComplete from '../../../../../components/GoogleMapsAutoComplete';

import '../styles.css';

// Form Step 1
const LeagueDetailsForm = ({ sites, facilities }) => {
  const [isFocused, setFocused] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext();

  return (
    <>
      <div>League details</div>
      <div className="input-league-form-wrapper">
        <PBInput
          id="name"
          type="text"
          value={values.name}
          isError={touched?.name && Boolean(errors.name)}
          errorMessage={touched?.name && errors.name}
          onChange={handleChange}
          label="Name"
          required
          onBlur={handleBlur}
        />
      </div>
      <div className="input-league-form-wrapper">
        <PBInput
          id="description"
          type="textarea"
          rows="4"
          multiline
          isError={touched?.description && Boolean(errors.description)}
          errorMessage={touched?.description && errors.description}
          value={values.description}
          onChange={handleChange}
          label="Description"
          required
          onBlur={handleBlur}
        />
      </div>
      <div className="input-league-form-wrapper">
        <PBInput
          id="additionalInfo"
          type="textarea"
          rows="4"
          multiline
          isError={touched?.additionalInfo && Boolean(errors.additionalInfo)}
          errorMessage={touched?.additionalInfo && errors.additionalInfo}
          value={values.additionalInfo}
          onChange={handleChange}
          label="Additional League Info"
          onBlur={handleBlur}
        />
      </div>

      <div>
        <MenuHeading title="Start and End Date " />
        <DateRangePicker
          startDateId="startTime"
          endDateId="endTime"
          startDate={moment(values.startDate)}
          endDate={moment(values.endDate)}
          onDatesChange={({ startDate, endDate }) => {
            setFieldValue('startDate', startDate);
            setFieldValue('endDate', endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(newFocusedInput) => setFocusedInput(newFocusedInput)}
          minimumNights={0}
          numberOfMonths={1}
          displayFormat="ddd, DD-MMM-YYYY"
          noBorder
        />
      </div>

      <ConditionallyVisible condition={sites?.length > 0}>
        <PBInput
          id="isOnSite"
          type="toggle"
          label="Will this league be held on site?"
          value={values.isOnSite}
          onChange={handleChange}
        />

        <ConditionallyVisible condition={values.isOnSite}>
          <PBInput
            id="Site"
            value={values.Site}
            onChange={handleChange}
            type="select"
            label="Site"
            isError={touched?.Site && Boolean(errors.Site)}
            errorMessage={touched?.Site && errors.Site}
            options={sites.map((site) => ({ label: site.name, value: site.id }))}
          />

          <div>
            <div style={{ marginBottom: '0.5rem' }}>
              <small>You can specify what facilities you want your league to run on</small>
            </div>
            <FacilityAndSubFacilityPicker
              facilities={facilities?.filter((x) => x.siteId === values.Site)}
              checkedFacilities={values.facilities}
              onUpdate={(e) => setFieldValue('facilities', e.map((x) => x.id))}
              editingAccessRestriction={false}
            />
          </div>
        </ConditionallyVisible>
      </ConditionallyVisible>

      <ConditionallyVisible condition={sites?.length <= 0 || !values.isOnSite}>
        <div className="input-league-form-wrapper">
          <GoogleMapsAutoComplete
            locationDescription={values.locationDescription}
            onChange={(newValue) => {
              setFieldValue('location', newValue.location);
              setFieldValue('locationDescription', newValue.locationDescription);
              setFieldValue('locationChanged', true);
            }}
          />
        </div>
      </ConditionallyVisible>

      <div className="input-league-form-wrapper">
        <FormControlLabel
          control={(
            <SingleDatePicker
              date={moment(values.closingDate)}
              onDateChange={(date) => setFieldValue(
                'closingDate', moment(date).format('YYYY-MM-DD HH:mm'),
              )}
              style={{ width: 200 }}
              focused={isFocused}
              onFocusChange={({ focused }) => setFocused(focused)}
              displayFormat="ddd, DD/MM/YY"
              numberOfMonths={1}
              isOutsideRange={() => false}
              fullWidth
              noBorder
            />
  )}
          label="Closing Date"
          labelPlacement="start"
        />
        <BasicTooltip title={lang.closingDateTooltip} />
      </div>
    </>
  );
};

LeagueDetailsForm.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LeagueDetailsForm;
