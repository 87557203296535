import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import emailValidator from 'email-validator';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as timetableActions from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import * as actions from '../../../../reducers/subscriptionsReducer';
import BlockBookingStepperSteps from './BlockBookingStepperSteps';
import BookingStepperStyles from './styles/BookingStepperStyles';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { validateUserEmail } from '../../../../services/usersServices';

const BlockBookingStepperWrapper = ({ classes }) => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const {
    subscription,
    selectedTimeslots,
    conflicts,
    facility,
    subFacility,
    isLoadingConflicts,
    applyExclusions,
    pitchSplit,
    userDetailsForSubscription,
  } = useSelector((state) => ({
    subscription: state.subscriptions.subscription,
    facility: state.facilities.selectedFacility,
    subFacility: state.facilities.selectedSubFacility,
    conflicts: state.subscriptions.conflicts,
    applyExclusions: state.subscriptions.applyExclusions,
    isLoadingConflicts: state.subscriptions.isLoadingConflicts,
    userDetailsForSubscription: state.subscriptions.userDetailsForSubscription,
    selectedTimeslots: state.timetable.selectedTimeslots,
    pitchSplit: state.booking.selectedPitchSplit,
  }));

  useEffect(() => {
    dispatch(facilitiesActions.requestFacilitiesRetrieval());
  }, [dispatch]);

  useEffect(() => {
    const isSetFromReservation = selectedTimeslots.length <= 0
    && subscription.facilityId && subscription.validFrom;
    if (
      emailValidator.validate(userDetailsForSubscription?.email) && isSetFromReservation
    ) {
      if (facility.id) {
        setActiveStep(2);
      } else {
        setActiveStep(1);
      }
    }
  }, [userDetailsForSubscription, facility]);

  const closeStepper = () => {
    setActiveStep(0);
    dispatch(actions.toggleSubscriptionCreationPopup());
    dispatch(actions.resetSubscriptionStore());
    dispatch(timetableActions.resetTimetableData());
    dispatch(facilitiesActions.resetSelectedFacilityStore());
  };

  const handleNext = async () => {
    if (activeStep === 0 && !userDetailsForSubscription?.id) {
      const res = await validateUserEmail(userDetailsForSubscription.email, 'New User Signup');
      if (res.status !== 200) {
        alert('This email address is not valid. Please double check it and enter a valid one, or contact support.');
        return;
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const createSubscription = (checkForConflicts) => {
    dispatch(actions.requestSubscriptionCreation(checkForConflicts));
    closeStepper();
  };

  const resetData = () => {
    dispatch(facilitiesActions.resetSelectedFacilityStore());
    dispatch(actions.toggleSubscriptionCreationPopup());
  };

  const steps = BlockBookingStepperSteps();
  const numberOfSteps = steps.length;
  const activeStepObject = steps[activeStep];
  const { facilityIds } = subscription;
  const { splitType, subFacilities } = facility;

  let conflictingSubscriptions = 0;
  let conflictingAllocations = 0;
  let conflictMatchesParent = false;
  if (conflicts) {
    conflicts.conflictingFacilitySubscriptions.forEach((conflict) => {
      conflict.accessRestriction.facilities.forEach(() => {
        conflictingSubscriptions += 1;
      });
    });
    conflictingAllocations = conflicts.conflictingAllocations.length;
    conflictMatchesParent = conflicts.conflictingAllocations.some(
      (allocation) => allocation.facilityId === facility.id,
    );
  }

  const totalSubFacilities = subFacilities && subFacilities.length > 0 ? subFacilities.length : 1;
  const isBackDisabled = activeStep === 0;
  let isNextDisabled = false;

  switch (activeStep) {
    case 0:
      isNextDisabled = !emailValidator.validate(subscription.email) || !subscription.email;
      break;
    case 1:
      isNextDisabled = !facility.id || selectedTimeslots.length === 0;
      break;
    case 2:
      isNextDisabled = subscription.validToType !== 'INDEFINITELY' && !subscription.validTo;
      break;
    case 3:
      if (isLoadingConflicts) {
        isNextDisabled = true;
        break;
      }

      if ((splitType !== 'USER_SELECTION' && splitType !== 'MULTIPLE_SELECTION')
       && ((conflictingSubscriptions + pitchSplit > totalSubFacilities && !applyExclusions)
         || (conflictingAllocations + pitchSplit > totalSubFacilities && !applyExclusions))) {
        isNextDisabled = true;
        break;
      }
      if (subFacility && subFacility.type === 'SUB_FACILITY' && ((conflictingAllocations > 0 && !applyExclusions) || (conflictingSubscriptions > 0 && !applyExclusions))) {
        isNextDisabled = true;
        break;
      }
      if (splitType === 'USER_SELECTION' && (facilityIds.length !== pitchSplit || (conflictMatchesParent && !applyExclusions))) {
        isNextDisabled = true;
        break;
      }
      if (splitType === 'MULTIPLE_SELECTION' && facilityIds.length !== pitchSplit) {
        isNextDisabled = true;
        break;
      }
      break;
    case 4:
      isNextDisabled = subscription.subscriptionName === '';
      break;
    default:
      break;
  }

  const backButton = activeStep === 0 ? <div /> : (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleBack}
      disabled={isBackDisabled}
    >
      back
    </Button>
  );

  const nextButton = activeStep === numberOfSteps - 1 ? (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        isNextDisabled = true;
        createSubscription(false);
      }}
      disabled={isNextDisabled}
    >
      Confirm booking
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      disabled={isNextDisabled}
      onClick={handleNext}
    >
      next
    </Button>
  );

  return (
    <div className="reservation-creation-stepper">
      <div
        className="reservation-creation-stepper-header"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          {`Step ${activeStep + 1} of ${numberOfSteps}`}
        </Typography>
        <CloseDialogIcon onClick={resetData} />
      </div>
      <div className="reservation-creation-stepper-body">
        {activeStepObject.view}
      </div>
      <div className="reservation-creation-stepper-navigation">
        <Stepper
          variant="none"
          type="text"
          steps={numberOfSteps}
          activeStep={activeStep}
          onBack={handleBack}
          onNext={handleNext}
          nextButton={subscription.type === 'CARD_NOT_PRESENT' ? null : nextButton}
          backButton={backButton}
          position="static"
        />
      </div>
    </div>
  );
};

BlockBookingStepperWrapper.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(BookingStepperStyles)(BlockBookingStepperWrapper);
