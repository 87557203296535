import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import emailValidator from 'email-validator';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { requestAllocationsCreation } from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';
import * as timetableActions from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import { CircularProgress } from '@material-ui/core';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import BookingStepperSteps from './BookingStepperSteps';
import BookingStepperStyles from '../../styles/BookingStepperStyles';
import { validateUserEmail } from '../../../../services/usersServices';

class BookingStepperWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      isMobile: false,
    };
  }

  componentDidMount() {
    const { requestFacilitiesRetrieval } = this.props;
    requestFacilitiesRetrieval();

    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  }

  closeStepper = () => {
    const {
      resetManagerReservationCreation,
      redirectToCalendar,
      resetSelectedFacilityStore,
    } = this.props;
    this.setState({
      activeStep: 0,
    }, () => resetManagerReservationCreation());
    resetSelectedFacilityStore();
    redirectToCalendar();
  };

  handleNext = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) {
      const { doRequestAllocationsCreation, timetableData } = this.props;
      if (timetableData.selectedTimeslots.length > 0) {
        doRequestAllocationsCreation();
      }
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      this.setState({
        activeStep: activeStep + 1,
      });
    }
  };

  handleBack = () => {
    const { ageGroupBookingModifiers } = this.props;
    const { activeStep } = this.state;
    if (ageGroupBookingModifiers && ageGroupBookingModifiers.length === 0 && activeStep === 4) {
      this.setState({ activeStep: activeStep - 2 });
    } else {
      this.setState({
        activeStep: activeStep - 1,
      });
    }
  };

  render() {
    const steps = BookingStepperSteps();

    const {
      timetableData,
      userDetailsForReservation,
      selectedFacility,
      reservationsCreationSuccess,
      basketItems,
      currencySym,
      priceRetrieved,
      isAvailabilityLoading,
    } = this.props;

    const { firstName, lastName, email } = userDetailsForReservation;
    const { priceForSlots } = timetableData;

    const { activeStep, isMobile } = this.state;

    const numberOfSteps = steps.length;
    const activeStepObject = steps[activeStep];

    const isBackDisabled = activeStep === 0;
    let isNextDisabled = activeStep === (numberOfSteps - 1);
    switch (activeStep) {
      case 0:
        if (firstName === '' || lastName === '' || !emailValidator.validate(email)) {
          isNextDisabled = true;
        }
        break;
      case 2:
        if (!basketItems.length > 0 && !priceRetrieved) {
          isNextDisabled = true;
        }
        break;
      default:
        break;
    }

    let price = 'Continue to view price';

    if (priceForSlots !== '') {
      price = `${currencySym}${priceForSlots}`;
    }

    if (reservationsCreationSuccess) {
      this.closeStepper();
    }

    const stepperStyle = isMobile
      ? {
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100%',
        alignItems: 'normal',
        gap: '1rem',
      }
      : { position: 'relative', width: '100%', marginTop: '10px' };

    const checkButtonStatus = () => {
      if (activeStep === 1 && selectedFacility?.id === null) {
        return 'Select a facility';
      } if (isAvailabilityLoading) {
        return <CircularProgress size={24} color="secondary" />;
      }
      return 'Next';
    };

    return (
      <div className="reservation-creation-stepper">
        <div className="reservation-creation-stepper-header">
          <Typography>
            {`Step ${activeStep + 1} of ${numberOfSteps} ${selectedFacility.name}`}
          </Typography>
          <ConditionallyVisible condition={activeStep > 0}>
            <Typography>
              {`Booker: ${email}`}
            </Typography>
          </ConditionallyVisible>
          <ConditionallyVisible condition={activeStep === 1}>
            <Typography>
              {`Price: ${price}`}
            </Typography>
          </ConditionallyVisible>
        </div>
        <div className="reservation-creation-stepper-body">
          <ConditionallyVisible condition={activeStep === 2}>
            <Button className="reservation-creation-stepper-add-bookings" variant="contained" color="accent" onClick={this.handleBack} disabled={isBackDisabled}>Add more bookings for this user</Button>
          </ConditionallyVisible>
          {activeStepObject.view}
        </div>
        <Stepper
          style={stepperStyle}
          variant="none"
          type="text"
          steps={numberOfSteps}
          activeStep={activeStep}
          onBack={this.handleBack}
          onNext={this.handleNext}
          nextButton={!isNextDisabled && (
            <Button
              id="nexxtBUTTON"
              variant="contained"
              color="primary"
              onClick={async () => {
                if (activeStep === 0 && !userDetailsForReservation?.id) {
                  const res = await validateUserEmail(email, 'New User Signup');
                  if (res.status !== 200) {
                    alert('This email address is not valid. Please double check it and enter a valid one, or contact support.');
                    return;
                  }
                }
                this.handleNext();
              }}
              disabled={isNextDisabled
                || isAvailabilityLoading
                || (activeStep === 1 && selectedFacility?.id === null)}
            >
              {checkButtonStatus()}
            </Button>
          )}
          backButton={<Button variant="contained" color="secondary" onClick={this.handleBack} disabled={isBackDisabled}>Back</Button>}
          position="static"
        />
      </div>
    );
  }
}

BookingStepperWrapper.propTypes = {
  userDetailsForReservation: PropTypes.shape().isRequired,
  timetableData: PropTypes.shape().isRequired,
  ageGroupBookingModifiers: PropTypes.arrayOf(PropTypes.string),
  selectedFacility: PropTypes.shape().isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  resetSelectedFacilityStore: PropTypes.func.isRequired,
  reservationsCreationSuccess: PropTypes.bool.isRequired,
  resetManagerReservationCreation: PropTypes.func.isRequired,
  doRequestAllocationsCreation: PropTypes.func.isRequired,
  redirectToCalendar: PropTypes.func.isRequired,
  basketItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currencySym: PropTypes.string,
  priceRetrieved: PropTypes.bool,
  isAvailabilityLoading: PropTypes.bool.isRequired,
};

BookingStepperWrapper.defaultProps = {
  ageGroupBookingModifiers: [],
  currencySym: '£',
  priceRetrieved: false,
};

const mapStateToProps = (state) => ({
  userDetailsForReservation: state.reservation.userDetailsForReservation,
  timetableData: state.timetable,
  selectedFacility: state.facilities.selectedFacility,
  ageGroupBookingModifiers: state.facilities.selectedFacility.ageGroupBookingModifiers,
  eventBookingModifiers: state.facilities.selectedFacility.eventBookingModifiers,
  requestingReservationsCreation: state.reservation.requestingReservationsCreation,
  reservationsCreationSuccess: state.reservation.reservationsCreationSuccess,
  createdReservations: state.reservation.createdReservations,
  basketItems: state.basket.confirmedAllocations,
  priceRetrieved: state.basket.priceRetrieved,
  currencySym: state.companies.companyInfo.currencySym,
  isAvailabilityLoading: state.timetable.isAvailabilityLoading,
});

const mapDispatchToProps = (dispatch) => ({
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  doResetTimetableData: () => dispatch(timetableActions.resetTimetableData()),
  resetManagerReservationCreation: () => dispatch(
    reservationsActions.resetManagerReservationCreation(),
  ),
  doRequestAllocationsCreation: () => dispatch(requestAllocationsCreation()),
  redirectToCalendar: () => dispatch(push('/calendar')),
  resetSelectedFacilityStore: () => dispatch(
    facilitiesActions.resetSelectedFacilityStore(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(BookingStepperStyles)(BookingStepperWrapper),
);
