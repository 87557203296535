import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import * as basketActions from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';
import * as timetableActions from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import { resetBookingStore } from '@pitchbooking-dev/pb-shared/lib/reducers/bookingReducer';
import BookingStepperWrapper from './BookingStepperWrapper';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';

class BookingCreationDialog extends React.Component {
  clearBasket = () => {
    const { basketItems, requestAllocationRemoval } = this.props;
    basketItems.forEach((confirmedAllocation) => {
      requestAllocationRemoval(confirmedAllocation.id);
    });
  };

  resetData = () => {
    const {
      toggleManagerReservationsCreationPopup,
      resetTimetableData,
      resetSelectedFacilityStore,
      reservationsCreationSuccess,
      resetManagerReservationCreation,
      resetPriceRetrieval,
      doResetBookingStore,
    } = this.props;
    toggleManagerReservationsCreationPopup();
    resetTimetableData();
    doResetBookingStore();
    resetPriceRetrieval();
    if (!reservationsCreationSuccess) {
      this.clearBasket();
    }
    resetSelectedFacilityStore();
    resetManagerReservationCreation();
  };

  render() {
    const {
      reservationsCreationPopupOpen,
      toggleManagerReservationsCreationPopup,
    } = this.props;
    return (
      <div>
        <Button id="manager-calendar-new-booking" variant="contained" color="primary" onClick={() => toggleManagerReservationsCreationPopup()}>Create New Booking</Button>
        <Dialog
          className="reservation-creation-modal-wrapper"
          open={reservationsCreationPopupOpen}
          onRequestClose={() => this.resetData()}
          fullScreen
        >
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => this.resetData()} />
          </DialogActions>
          <DialogContent style={{ width: 'flex' }}>
            <BookingStepperWrapper handleClose={() => this.resetData()} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

BookingCreationDialog.propTypes = {
  reservationsCreationPopupOpen: PropTypes.bool.isRequired,
  reservationsCreationSuccess: PropTypes.bool.isRequired,
  toggleManagerReservationsCreationPopup: PropTypes.func.isRequired,
  resetTimetableData: PropTypes.func.isRequired,
  resetSelectedFacilityStore: PropTypes.func.isRequired,
  resetPriceRetrieval: PropTypes.func.isRequired,
  requestAllocationRemoval: PropTypes.func.isRequired,
  resetManagerReservationCreation: PropTypes.func.isRequired,
  doResetBookingStore: PropTypes.func.isRequired,
  basketItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  reservationsCreationPopupOpen: state.reservation.reservationsCreationPopupOpen,
  basketItems: state.basket.confirmedAllocations,
  reservationsCreationSuccess: state.reservation.reservationsCreationSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  toggleManagerReservationsCreationPopup: () => dispatch(
    reservationsActions.toggleManagerReservationsCreationPopup(),
  ),
  resetTimetableData: () => dispatch(
    timetableActions.resetTimetableData(),
  ),
  resetPriceRetrieval: () => dispatch(
    basketActions.priceRetrievedReset(),
  ),
  resetSelectedFacilityStore: () => dispatch(
    facilitiesActions.resetSelectedFacilityStore(),
  ),
  requestAllocationRemoval: (id) => dispatch(basketActions.requestAllocationRemoval(id)),
  resetManagerReservationCreation: () => dispatch(
    reservationsActions.resetManagerReservationCreation(),
  ),
  doResetBookingStore: () => dispatch(resetBookingStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingCreationDialog);
