import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import moment from 'moment';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@material-ui/core';
import { fetchOrders } from '../../reducers/addonsReducer';
import DateRangePickerAndQuickDates from '../../components/Date/DateRangePickerAndQuickDatesWrapper';

const OrderHistory = () => {
  const [selectedView, setSelectedView] = useState('ORDER_HISTORY');
  const [selectedDates, setSelectedDates] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const orders = useSelector(
    (state) => state.addons.posOrders,
  )?.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));
  const currency = useSelector((state) => state.companies.companyInfo.currency);

  const dispatch = useDispatch();

  const getInventorySales = () => {
    dispatch(fetchOrders(selectedView, selectedDates));
  };

  useEffect(() => {
    // Your code here
    if (selectedView === 'ORDER_HISTORY') {
      dispatch(fetchOrders());
    }
    if (selectedView === 'INVENTORY_SALES') {
      dispatch(fetchOrders(selectedView));
    }
  },
  [selectedView]);

  return (
    <div>
      <Paper style={{ padding: 15 }}>
        <h1>Select Your Report</h1>
        <Select value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
          <option value="ORDER_HISTORY">Order History</option>
          <option value="INVENTORY_SALES">Inventory Sales</option>
        </Select>
        {selectedView === 'ORDER_HISTORY' && (
        <>
          <h1>Order History</h1>
          <MaterialTable
            data={orders}
            title="Pitchbooking POS Orders"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
            }}
            columns={[
              {
                title: 'Order ID',
                field: 'id',
                render: (row) => (
                  <a href={`/point-of-sale/${row.id}`}>{row.id}</a>
                ),
              },
              {
                title: 'Created at',
                customSort: (a, b) => a.createdAt.localeCompare(b.createdAt),
                render: (row) => (
                  <p>
                    {moment(row.createdAt).format('ddd, DD MMM YYYY HH:mm')}
                  </p>
                ),
              },
              {
                title: 'Order Total',
                render: (row) => (
                  <p>
                    {getCurrencySymbol(currency)}
                    {row.total?.toFixed(2)}
                  </p>
                ),
              },
              {
                title: 'Payment Status',
                field: 'paymentStatus',
              },
              {
                title: 'Status',
                field: 'status',
              },
            ]}
            options={{
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking POS Orders',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </>
        )}
        {selectedView === 'INVENTORY_SALES' && (
        <>

          <Paper
            style={{
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              overflowX: 'hidden',
              width: '100%',
              zIndex: 1,
            }}
            component={Paper}
          >
            <h1>Inventory Sales</h1>
            <div style={{
              display: 'flex',
              flexWrap: 'none',
              marginTop: '20px',
              marginLeft: '20px',
              marginBottom: '40px',
              padding: '20px',
              alignItems: 'center',
              zIndex: 1,
            }}
            >
              <DateRangePickerAndQuickDates
                startDate={selectedDates !== null ? moment(selectedDates?.startDate)
                  : selectedDates.startDate}
                endDate={selectedDates !== null ? moment(selectedDates?.endDate)
                  : selectedDates.endDate}
                onDatesChange={(dates) => setSelectedDates(dates)}
              />
              <Button
                style={{
                  width: 150, height: 56, marginLeft: 20, marginRight: 20,
                }}
                color="secondary"
                variant="contained"
                onClick={() => getInventorySales()}
              >
                Update
              </Button>
            </div>
            <MaterialTable
              data={orders}
              title="Pitchbooking Inventory Sales"
              style={{ zIndex: 0 }}
              icons={{
                NextPage: () => <ChevronRight />,
                PreviousPage: () => <ChevronLeft />,
                DetailPanel: () => <ChevronRight />,
                Search: () => <Search />,
                Export: () => <SaveAlt />,
                ResetSearch: () => <Clear />,
                SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
              }}
              columns={[
                {
                  title: 'Category Name',
                  field: 'categoryName',
                  render: (row) => (
                    <p>{row.categoryName}</p>
                  ),
                },
                {
                  title: 'Quantity Sold',
                  render: (row) => (
                    <p>
                      {row.totalQuantity}
                    </p>
                  ),
                },
                {
                  title: 'Total',
                  render: (row) => (
                    <p>
                      {getCurrencySymbol(currency)}
                      {row?.totalCost?.toFixed(2)}
                    </p>
                  ),
                },
              ]}
              detailPanel={(rowData) => (
                <div style={{
                  padding: '20px',
                  backgroundColor: '#f9f9f9',
                  border: '1px solid #e0e0e0',
                  borderRadius: '5px',
                }}
                >
                  <Table>
                    <TableHead>
                      <TableCell> Item</TableCell>
                      <TableCell>Quantity Sold</TableCell>
                      <TableCell>Total</TableCell>
                    </TableHead>
                    {rowData.items.map((item) => (
                      <TableRow>
                        <TableCell>{item.addonName}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          {getCurrencySymbol(currency)}
                          {item.totalCost.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </div>
              )}
              options={{
                search: true,
                selection: false,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                exportButton: false,
                exportFileName: 'Pitchbooking POS Orders',
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastPageButtons: false,
                showTitle: false,
                headerStyle: {
                  backgroundColor: '#efefef',
                  fontWeight: 'bold',
                },
              }}
              localization={{
                toolbar: {
                  exportTitle: 'Download',
                },
              }}
            />
          </Paper>
        </>
        )}
      </Paper>
    </div>
  );
};

export default OrderHistory;
