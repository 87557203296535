/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../styles/dashboard.css';

const bookingsCard = ({
  booking,
}) => {
  const {
    fullName, facilityName, startTime, paymentStatus, endTime, id, facility, user,
  } = booking;

  const {
    timezone,
  } = facility;

  const {
    teamName,
  } = user;

  let paymentStatusText = '';

  switch (paymentStatus) {
    case 'CARD':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    case 'CASH':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    case 'ZELLE':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    case 'PLEI':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    case 'BANKTRANSFER':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    case 'UNPAID':
      paymentStatusText = <div className="dashboard-card-paymentstatus unpaid">Unpaid</div>;
      break;
    case 'NOT_CHARGED':
      paymentStatusText = <div className="dashboard-card-paymentstatus not-charged">Not Charged</div>;
      break;
    case 'INVOICE':
      paymentStatusText = <div className="dashboard-card-paymentstatus invoice">Invoiced</div>;
      break;
    case 'STRIPE':
      paymentStatusText = <div className="dashboard-card-paymentstatus paid">Paid</div>;
      break;
    default:
      paymentStatusText = '';
      break;
  }

  return (
    <Link
      href={`/bookings/${id}`}
      style={{ color: '#333', textDecoration: 'none' }}
      to={`/bookings/${id}`}
    >
      <div className="dashboard-card">
        <div className="dashboard-card-contents">
          <div className="dashboard-card-title">
            {fullName}
          </div>
          <div className="dashboard-card-title">
            {teamName}
          </div>
          {facilityName}
          <div className="dashboard-card-reservation-time">
            {moment(startTime).tz(timezone).format('LT')}
            {' - '}
            {moment(endTime).tz(timezone).add(1, 'minutes').format('LT')}
          </div>
          <div className="dashboard-card-paymentstatus">
            {paymentStatusText}
          </div>
        </div>
      </div>
    </Link>
  );
};

bookingsCard.propTypes = {
  booking: PropTypes.shape().isRequired,
  paymentStatus: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  facilityName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default (bookingsCard);
