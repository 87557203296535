import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import EditCurrentBooking from './EditCurrentBooking';
import ChangeAllocation from './ChangeAllocation';
import { requestFacilityRetrieval, requestFacilitiesRetrieval } from '../../reducers/facilitiesReducer';
import { updateBookingsEditSuccess, resetSelectedRows, updateBookingEditFailed } from '../../reducers/bookingsReducer';
import { excludeSlotAndCreateReservation } from '../../reducers/subscriptionsReducer';

// Modals open trigger is when allocation is set
const FacilityUpdateDialog = ({
  allocation,
  onChange,
  onClose,
  subscription,
}) => {
  const dispatch = useDispatch();
  const editSuccess = useSelector((state) => state.bookings.editSuccess);
  const editFailed = useSelector((state) => state.bookings.editFailed);
  const priceForSlots = useSelector((state) => state.timetable.priceForSlots);
  const [tab, setTab] = useState(0);
  const [activeAllocation, setActiveAllocation] = useState(allocation);
  const [newPrice, setNewPrice] = useState(null);
  const [editPrice, setEditPrice] = useState(false);
  const isMobile = window.innerWidth < 768;

  const activeFacility = useMemo(() => {
    if (!allocation && !subscription) return null;
    if (subscription) {
      return subscription.accessRestriction.facilities[0];
    }
    if (allocation.allocations && allocation.allocations.length > 0) {
      return allocation.allocations.find((x) => x.status === 'ACTIVE' && x.type === 'FACILITY' && !x.forcedBooking)?.facility;
    }
    return allocation.facility;
  }, [activeAllocation]);

  const handleClose = () => {
    setActiveAllocation(null);
    dispatch(updateBookingsEditSuccess(null));
    dispatch(updateBookingEditFailed(false));
    dispatch(resetSelectedRows());
    onClose();
  };

  useEffect(() => {
    if (activeFacility) {
      dispatch(
        requestFacilityRetrieval(
          activeFacility.parentFacilityId ? activeFacility.parentFacilityId : activeFacility?.id,
        ),
      );
    }
  }, [activeFacility]);

  useEffect(() => {
    dispatch(
      requestFacilitiesRetrieval(),
    );
  }, []);

  useEffect(() => {
    if (allocation && !subscription) {
      setActiveAllocation(allocation);
    }

    if (subscription) {
      const startTime = moment(subscription.accessRestriction.startTime, 'HH:mm:ss');
      const endTime = moment(subscription.accessRestriction.endTime, 'HH:mm:ss');
      const duration = endTime.diff(startTime, 'seconds');
      setActiveAllocation(
        {
          startTime: moment(subscription.slot),
          endTime: moment(subscription.slot).add(duration, 'seconds'),
          ...subscription,
        },
      );
    }
  }, [allocation, subscription]);

  return (
    <Dialog
      open={activeAllocation}
      onRequestClose={handleClose}
      fullScreen
      disableEnforceFocus
      style={{
        padding: '2rem',
      }}
    >
      <DialogActions variant="none">
        <CloseDialogIcon onClick={handleClose} />
      </DialogActions>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {editFailed && (
          <Typography variant="h5" color="error">
            Edit Booking Failed
          </Typography>
        )}
        {editSuccess ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <Typography variant="h5">
                {editSuccess.message}
              </Typography>

              <div>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Original booking
                </Typography>

                <div>
                  <Typography variant="h5">{activeAllocation.facilityName}</Typography>
                  <Typography variant="h5">
                    {`
                      ${moment(activeAllocation.startTime).format('DD-MMM-YYYY')}, 
                      ${moment(activeAllocation.startTime).format('HH:mm')} - ${moment(activeAllocation.endTime).add(1, 's').format('HH:mm')}
                    `}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Edited Booking
                </Typography>

                <div>
                  <Typography variant="h5">{editSuccess.facility}</Typography>
                  <Typography variant="h5">
                    {`
                      ${editSuccess.date}, 
                      ${editSuccess.startTime} - ${editSuccess.endTime}
                    `}
                  </Typography>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            {activeAllocation && (
            <div className="current_allocations">
              <div>
                <Typography variant="h5">
                  {activeFacility?.name}
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {`${moment(activeAllocation.startTime).format('DD-MMM-YYYY HH:mm')} - ${moment(activeAllocation.endTime).add(1, 'second').format('HH:mm')}`}
                </Typography>
                {subscription && (
                <div style={{
                  display: 'flex', alignItems: 'center', gap: 2, flexDirection: isMobile ? 'column' : 'row',
                }}
                >
                  <Typography variant="body1">
                    Price:
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={editPrice ? newPrice : (subscription?.amount / 100).toFixed(2)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getCurrencySymbol(subscription.company.currency)}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setNewPrice(e.target.value)}
                    disabled={!editPrice}
                  />
                  {!editPrice ? (
                    <>
                      <EditIcon
                        onClick={
                      () => {
                        setEditPrice(!editPrice); setNewPrice(subscription.amount / 100);
                      }
                        }
                      />
                      Edit Price
                    </>
                  ) : (
                    <div style={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row' }}>
                      <div>
                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                          Original Price:
                          {getCurrencySymbol(subscription.company.currency)}
                          {(subscription?.amount / 100).toFixed(2)}
                        </Typography>
                        {priceForSlots !== '0.00' && (
                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                          Newly selected slots regular price:
                          {getCurrencySymbol(subscription.company.currency)}
                          {priceForSlots}
                        </Typography>
                        )}

                      </div>
                      <div>
                        {/* if price is changed without a slot change send the
                       original subscription times as new slot need to do this to
                      apply the exclusion and capture the new price
                       */}
                        {priceForSlots === '0.00' && editPrice && newPrice !== (subscription?.amount / 100) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => dispatch(excludeSlotAndCreateReservation(
                            {
                              accessRestrictionId: subscription.accessRestrictionId,
                              date: subscription.slot,
                              subscriptionId: subscription.id,
                              totalPaid: subscription.totalPaid,
                              newPrice: newPrice * 100,
                            }, {
                              startTime: moment(activeAllocation.startTime)
                                .tz(subscription.company.timezone, true).format(),
                              endTime: moment(activeAllocation.endTime)
                                .tz(subscription.company.timezone, true).format(),
                              facilityId: activeFacility.id,
                            },
                          ))}
                        >
                          Update Price Only
                        </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                )}
              </div>
            </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {!subscription && (
              <Tabs value={tab} onChange={(e, index) => setTab(index)}>
                <Tab label="Edit Date/Time" />
                <Tab label="Change Facility" />
              </Tabs>
              )}

              <>
                <ConditionallyVisible condition={tab === 0 && !subscription}>
                  <EditCurrentBooking
                    facility={activeFacility}
                    updateFacility={onChange}
                    date={moment(allocation?.startTime).format()}
                    allocation={allocation}
                    subscription={subscription}
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={tab === 1 || subscription}>
                  <ChangeAllocation
                    updateFacility={
                      !subscription
                        ? onChange
                        : (selectedFacility, timeslots, priceForSlots) => {
                          dispatch(excludeSlotAndCreateReservation(
                            {
                              accessRestrictionId: subscription.accessRestrictionId,
                              date: subscription.slot,
                              subscriptionId: subscription.id,
                              totalPaid: subscription.totalPaid,
                              newPrice: priceForSlots
                                ? parseInt(priceForSlots, 10) * 100 : newPrice * 100,
                            }, timeslots[0],
                          ));
                        }
}
                    subscription={subscription}
                    date={moment(activeAllocation?.startTime).format()}
                    currencySym={getCurrencySymbol(subscription?.company.currency)}
                    priceForSlots={
                      // if price has changed offer option to update it
                      (subscription?.amount / 100).toFixed(2) !== priceForSlots && !newPrice
                        ? priceForSlots : null
                    }
                  />
                </ConditionallyVisible>
              </>

              {editFailed && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          </>
        )}

      </DialogContent>
    </Dialog>
  );
};

FacilityUpdateDialog.propTypes = {
  allocation: PropTypes.shape.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.shape,
};

FacilityUpdateDialog.defaultProps = {
  subscription: null,
};

export default FacilityUpdateDialog;
