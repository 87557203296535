export const CALENDAR_USAGE_REQUESTED = 'CALENDAR_USAGE_REQUESTED';
export const CALENDAR_USAGE_FAILED = 'CALENDAR_USAGE_FAILED';
export const CALENDAR_USAGE_SUCCEEDED = 'CALENDAR_USAGE_SUCCEEDED';

export const COMPANY_SITES_REQUESTED = 'COMPANY_SITES_REQUESTED';
export const COMPANY_SITES_FAILED = 'COMPANY_SITES_FAILED';
export const COMPANY_SITES_SUCCEEDED = 'COMPANY_SITES_SUCCEEDED';

export const UPDATE_CALENDAR_USAGE_REQUESTED = 'UPDATE_CALENDAR_USAGE_REQUESTED';
export const UPDATE_CALENDAR_USAGE_FAILED = 'UPDATE_CALENDAR_USAGE_FAILED';
export const UPDATE_CALENDAR_USAGE_SUCCEEDED = 'UPDATE_CALENDAR_USAGE_SUCCEEDED';

export const UPDATE_COMPANY_SITES_REQUESTED = 'UPDATE_COMPANY_SITES_REQUESTED';
export const UPDATE_COMPANY_SITES_FAILED = 'UPDATE_COMPANY_SITES_FAILED';
export const UPDATE_COMPANY_SITES_SUCCEEDED = 'UPDATE_COMPANY_SUCCEEDED';

export const CREATE_CALENDAR_NOTE = 'CREATE_CALENDAR_NOTE';
export const DELETE_CALENDAR_NOTE_BY_ID = 'DELETE_CALENDAR_NOTE_BY_ID';

export const CALENDAR_RESERVATION_CREATION_LOADING = 'CALENDAR_RESERVATION_CREATION_LOADING';

export const initialState = {
  users: [],
  userError: '',
  calendarUsage: {},
  sites: [],
  isLoading: false,
  calendarReservationCreationLoading: false,
};

export function getCalendarUsage() {
  return { type: CALENDAR_USAGE_REQUESTED };
}
export function updateCalendarUsage(usage) {
  return { type: UPDATE_CALENDAR_USAGE_REQUESTED, usage };
}
export const createCalendarNote = (date, note) => (
  { type: CREATE_CALENDAR_NOTE, date, note }
);
export const deleteCalendarNoteById = (noteId, subscriptionId = null) => (
  { type: DELETE_CALENDAR_NOTE_BY_ID, noteId, subscriptionId }
);
export function updateCalendarUsageFail() {
  return { type: UPDATE_CALENDAR_USAGE_FAILED };
}
export function getCompanySites() {
  return { type: COMPANY_SITES_REQUESTED };
}
export function updateCompanySites(sites) {
  return { type: UPDATE_COMPANY_SITES_REQUESTED, sites };
}
export function updateReservationCreationLoading(loading) {
  return { type: CALENDAR_RESERVATION_CREATION_LOADING, loading };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CALENDAR_USAGE_REQUESTED:
      return {
        ...state,
        calendarUsage: action.usage,
        isLoading: false,
      };
    case CALENDAR_USAGE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CALENDAR_USAGE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_COMPANY_SITES_REQUESTED:
      return { ...state, sites: action.sites };
    case CALENDAR_RESERVATION_CREATION_LOADING:
      return { ...state, calendarReservationCreationLoading: action.loading };
    default:
      return state;
  }
};
