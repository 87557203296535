/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import { generateTotalWithTaxText, invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import fileDownload from 'js-file-download';
import { useCompany, useMobile } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { MaterialTable } from '../../../../components/MaterialTable';
import { valueToTagMap, generateOrderStatusTypeTag } from '../../../payments/OrderTable';
import DownloadPdf from '../../../../components/DownloadPdf';
import { PartialPaymentsNoteModal } from './PartialPaymentsNoteModal';

export const PartialPaymentsModal = ({
  isOpen, onClose, reservation,
}) => {
  const isMobile = useMobile();
  const { currencySym, stripeId } = useCompany();

  const formatExportData = (data) => data.map((row) => ({
    id: row.id,
    booker: `${row.user.firstName} ${row.user.lastName}`,
    total: (row.total / 100).toFixed(2),
    totalExTax: row.totalExTax.toFixed(2),
    tax: row.tax.toFixed(2),
    paymentMethod: row.paymentMethod,
    status: row.status,
    paidAt: `${moment(row.paidAt).format('DD/MM/YYYY h:mm a')}`,
    invoiceNumber: row.invoice && row.invoice.length ? invoiceNumberFormatted(row.invoice[0].invoiceNumber) : '',
  }));

  const handleCsvExport = (columns, data) => {
    const newData = formatExportData(data);
    const exportData = newData.map((row) => columns.map((columnDef) => row[columnDef.field])
      .join(','));
    const csvData = [
      columns.map((columnDef) => columnDef.title).join(','),
      ...exportData,
    ].join('\n');
    fileDownload(csvData, 'Pitchbooking Orders.csv');
  };

  const handlePdfExport = (columns, data) => {
    const newData = formatExportData(data);
    const dataArray = newData.map(
      (row) => [
        row.id,
        row.booker,
        row.total,
        row.totalExTax,
        row.tax,
        row.invoiceNumber ? `PB-INV-${row.invoiceNumber}` : 'N/A',
        row.paymentMethod,
        row.status,
        row.paidAt,
      ],
    );
    const columnsArray = columns.map((columnDef) => columnDef.title);
    DownloadPdf(columnsArray, dataArray, 'Pitchbooking Orders');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="lg"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>View Partial Payments</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <DialogContent>
        <MaterialTable
          data={reservation.partialPaymentOrders}
          columns={[
            {
              title: 'Order ID',
              field: 'id',
              render: (row) => (
                <a href={`/payments/${row.id}`}>
                  {row.id}
                </a>
              ),
            },
            {
              title: 'Booker',
              field: 'booker',
              render: (row) => {
                const name = row.isAnonymousPayment
                  ? row.note
                  : `${row.user?.firstName} ${row.user?.lastName}`;
                return (
                  <ListItemText
                    primary={name}
                    secondary={row.isAnonymousPayment ? '(No Account)' : row.user?.teamName}
                  />
                );
              },
            },
            {
              title: 'Total',
              field: 'total',
              render: (row) => (
                <ListItemText
                  primary={`${currencySym}${(row.total / 100).toFixed(2)}`}
                  secondary={generateTotalWithTaxText(row, currencySym, true)}
                />
              ),
            },
            {
              title: 'TotalExTax',
              field: 'totalExTax',
              hidden: true,
            },
            {
              title: 'Tax',
              field: 'tax',
              hidden: true,
            },
            {
              title: 'Invoice Number',
              field: 'invoiceNumber',
              hidden: true,
            },
            {
              title: 'Payment Method',
              field: 'paymentMethod',
              render: (row) => (
                valueToTagMap(
                  row.paymentMethod, stripeId, row.paymentId, row.subscriptions, row.invoice,
                )
              ),
            },
            {
              title: 'Status',
              field: 'status',
              render: (row) => (generateOrderStatusTypeTag(row.status)),
            },
            {
              title: 'Time Processed',
              field: 'paidAt',
              render: (row) => (
                `${moment(row.paidAt).format('D MMM YYYY, LT')}${row.createdByUser ? `,  by ${[row.createdByUser.firstName, row.createdByUser.lastName].join(' ')}` : ''}`
              ),
            },
            {
              title: 'Notes',
              field: null,
              sorting: false,
              render: (row) => {
                if (row.note) {
                  if (row?.note?.length > 30) {
                    return <PartialPaymentsNoteModal note={row.note} />;
                  }
                  return row.note;
                }
                return null;
              },
            },
          ]}
          options={{
            exportCsv: (columns, data) => handleCsvExport(columns, data),
            exportPdf: (columns, data) => handlePdfExport(columns, data),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          autoFocus
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
