import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import '../../styles/calendar.css';

const quickDateOptions = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { label: 'Next 7 Days', value: 'NEXT7' },
  { label: 'Past 7 Days', value: 'PAST7' },
  { label: 'This weekend', value: 'THISWEEKEND' },
  { label: 'Last weekend', value: 'LASTWEEKEND' },
  { label: 'Month remaining', value: 'TILLENDOFMONTH' },
  { label: 'Month to date', value: 'MONTHTODATE' },
  { label: 'Quarter to date', value: 'QUARTERTODATE' },
  { label: 'This Month', value: 'FULLMONTH' },
  { label: 'Next Month', value: 'NEXTMONTH' },
  { label: 'Last Month', value: 'LASTMONTH' },
  { label: 'Custom', value: 'CUSTOM' },
];

const DateRangePickerAndQuickDatesWrapper = ({
  includeQuickOptions, startDate, endDate, onDatesChange,
}) => {
  const [quickDateType, setQuickDateType] = useState(quickDateOptions[0]);
  const timezone = useSelector((state) => state.companies?.companyInfo?.timezone);
  const dateLocale = useSelector((state) => state.companies?.companyInfo?.dateLocale);

  const handleQuickDateChange = (option) => {
    setQuickDateType(option);

    switch (option.value) {
      case 'TODAY':
        onDatesChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone),
        });
        break;
      case 'YESTERDAY':
        onDatesChange({
          startDate: moment().tz(timezone).subtract(1, 'd'),
          endDate: moment().tz(timezone).subtract(1, 'd'),
        });
        break;
      case 'NEXT7':
        onDatesChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone).add(7, 'days'),
        });
        break;
      case 'PAST7':
        onDatesChange({
          startDate: moment().tz(timezone).subtract(7, 'days'),
          endDate: moment().tz(timezone),
        });
        break;
      case 'THISWEEKEND':
        onDatesChange({
          startDate: moment().tz(timezone).day(6),
          endDate: moment().tz(timezone).day(7),
        });
        break;
      case 'LASTWEEKEND':
        onDatesChange({
          startDate: moment().tz(timezone).subtract(7, 'days').day(6),
          endDate: moment().tz(timezone).subtract(7, 'days').day(7),
        });
        break;
      case 'TILLENDOFMONTH':
        onDatesChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone).endOf('month'),
        });
        break;
      case 'MONTHTODATE':
        onDatesChange({
          startDate: moment().tz(timezone).startOf('month'),
          endDate: moment().tz(timezone),
        });
        break;
      case 'QUARTERTODATE':
        onDatesChange({
          startDate: moment().tz(timezone).quarter(moment().tz(timezone).quarter()).startOf('quarter'),
          endDate: moment().tz(timezone),
        });
        break;
      case 'FULLMONTH':
        onDatesChange({
          startDate: moment().tz(timezone).startOf('month'),
          endDate: moment().tz(timezone).endOf('month'),
        });
        break;
      case 'NEXTMONTH':
        onDatesChange({
          startDate: moment().tz(timezone).add(1, 'month').startOf('month'),
          endDate: moment().tz(timezone).add(1, 'month').endOf('month'),
        });
        break;
      case 'LASTMONTH':
        onDatesChange({
          startDate: moment().tz(timezone).subtract(1, 'month').startOf('month'),
          endDate: moment().tz(timezone).subtract(1, 'month').endOf('month'),
        });
        break;
      case 'CUSTOM':
        onDatesChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone),
        });
        break;
      default:
        onDatesChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone),
        });
    }
  };

  return (
    <>
      <ConditionallyVisible condition={includeQuickOptions}>
        <Autocomplete
          value={quickDateType}
          onChange={(event, option) => handleQuickDateChange(option)}
          options={quickDateOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: 200, marginRight: 0, backgroundColor: 'white' }}
          renderInput={(params) => (
            <TextField {...params} label="" variant="outlined" />
          )}
          disableClearable
        />
      </ConditionallyVisible>
      <div className="date-range-picker">
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={(options) => onDatesChange(options)}
          noBorder
          numberOfMonths={1}
          minimumNights={0}
          displayFormat={
              dateLocale && dateLocale === 'en-US'
                ? 'MMM DD, YYYY'
                : 'DD MMM YYYY'
            }
          isOutsideRange={() => false}
        />
      </div>
    </>
  );
};

DateRangePickerAndQuickDatesWrapper.propTypes = {
  includeQuickOptions: PropTypes.bool,
  startDate: PropTypes.shape().isRequired,
  endDate: PropTypes.shape().isRequired,
  onDatesChange: PropTypes.func.isRequired,
};

DateRangePickerAndQuickDatesWrapper.defaultProps = {
  includeQuickOptions: true,
};

export default DateRangePickerAndQuickDatesWrapper;
