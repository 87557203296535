/* eslint-disable react/prop-types */
import React from 'react';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import UserSelectionInfoAlert from './UserSelectionInfoAlert';
import TabbedNavigation from '../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../containers/users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../containers/users/components/NewBookerUserCreation';

const UserSelector = ({ user, onChange, showAlert = true }) => {
  const tabs = [
    {
      id: 0,
      name: 'Existing Customer',
      body: (
        <PreviousBookerUserSearch
          updateUserDetails={onChange}
          user={user ?? {}}
        />
      ),
    },
    {
      id: 1,
      name: 'New Customer',
      body: (
        <NewBookerUserCreation
          updateUserDetails={onChange}
          user={user ?? {}}
        />
      ),
    },
  ];

  return (
    <div className="reservation-creation-user-selecton">
      <TabbedNavigation tabsToNavigate={tabs} />
      <ConditionallyVisible condition={showAlert}>
        <div className="tabbed-navigation-tabs-wrapper">
          <UserSelectionInfoAlert />
        </div>
      </ConditionallyVisible>
    </div>
  );
};

export default UserSelector;
