/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { updateSessionClient } from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import LoginPage from '../login';
import SignUp from '../sign-up';
import Billing from '../billing';
import Welcome from '../welcome';
import Product from '../product';
import StripeRequired from '../companySettings/StripeRequired';
import * as actions from '../../reducers/usersReducer';
import * as companyActions from '../../reducers/companiesReducer';
import MenuDrawer from '../../components/MenuDrawer/MenuDrawer';
import CompanyUpdateModal from '../../components/CompanyUpdateModal';
import ReservationCreationErrorDialog from '../bookings/components/ReservationCreationErrorDialog';
import MaintenanceMode from '../../components/MaintenanceMode';

import '../../styles/App.css';
import '../../styles/PBStyling.css';
import '../../shared-components/shared-components.css';
import NextSteps from '../welcome/nextSteps';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInformationOpen: true,
    };
  }

  componentDidMount = async () => {
    const {
      history,
      requestStripeAuthorisation,
      location,
      updateCurrentUser,
      getCompanyInfo,
      getCurrentUsersCompanies,
      doUpdateSessionClient,
    } = this.props;

    doUpdateSessionClient('pb-mgmt');
    let params = location.search;
    // if includes stripe auth code
    if (params.includes('&code')) {
      params = params.replace('?', '');
      const paramArray = params.split('&');
      const stripeAuthCode = paramArray[1].replace('code=', '');
      requestStripeAuthorisation(stripeAuthCode);
      history.push('/');
    }

    const token = localStorage.getItem('PBtoken');
    if (location.pathname === '/refresh-session') {
      if (token !== null) {
        this.logout();
      } else {
        history.push('/');
      }
    }

    if (token !== null) {
      const tokenDataEncoded = token.split('.')[1];
      const currentUser = JSON.parse(window.atob(tokenDataEncoded));
      updateCurrentUser(currentUser);
      getCurrentUsersCompanies(currentUser.userId);
      getCompanyInfo();

      if (Object.keys(currentUser).length !== 0) {
        try {
          if (process.env.NODE_ENV !== 'development') {
            initFullStory({ orgId: 'D0Y98' });
            FullStory('setIdentity', {
              uid: currentUser.userId,
              properties: {
                displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                email: `${currentUser.email}`,
                companyId: `${currentUser.managerAccess[0].companyId}`,
                client: 'pb-mgmt',
              },
            });
          }
        } catch (error) {
          console.log('FS failed to initialise: ', error);
        }
      }
    }
  }

  handleCompanyInformationClose(needsBilling, doubleBookingAlert, needsStripeVerification) {
    const {
      history,
    } = this.props;
    this.setState({ companyInformationOpen: false });
    if (!needsBilling && !doubleBookingAlert && !needsStripeVerification) {
      history.push('/company-settings');
    }
  }

  updateUserCompany(companyId) {
    const { updateUserSelectedCompany } = this.props;
    updateUserSelectedCompany(companyId);
  }

  logout() {
    const {
      updateCurrentUser, updateCompanyInfo, history,
    } = this.props;
    localStorage.removeItem('PBtoken');
    localStorage.removeItem('selectedCompany');
    history.push('/');
    updateCurrentUser({});
    updateCompanyInfo({});
    window.location.reload();
  }

  render() {
    let appView;
    const {
      currentUser, doUpdateUserPassword, companies,
    } = this.props;
    const { managerAccess } = currentUser;
    const { userCompanies } = companies;
    let role = null;
    if (managerAccess && managerAccess.length > 0) {
      const selectedCompany = localStorage.getItem('selectedCompany');
      if (selectedCompany) {
        role = userCompanies.find((obj) => obj.companyId === selectedCompany)?.role.type;
      } else {
        role = managerAccess[0].role.type;
      }
    }
    const { companyInfo } = companies;

    const { billings } = companyInfo;
    let billingInfo;
    if (billings && billings.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      billingInfo = billings.filter((billing) => (billing.type === 'STRIPE' || billing.gameOn) && billing.status === 'PENDING' && billing.required)?.[0];
    }

    if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
      appView = (
        <div>
          <MaintenanceMode />
        </div>
      );
      return appView;
    }

    if (!currentUser.email) {
      appView = (
        <div>
          <main>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/forms" component={LoginPage} />
              <Route exact path="/reset-password/:token" component={LoginPage} />
              <Route path="/signup/:token" component={SignUp} />
              <Route path="/agreement/:billingId" component={LoginPage} />
              <Route path="/welcome" component={LoginPage} />
              <Route path="/welcome-confirmed" component={LoginPage} />
              <Route path="/next-steps" component={LoginPage} />
              <Route exact path="/index.html" component={LoginPage} />
              <Route exact path="/bookings" component={LoginPage} />
              <Route exact path="/invoices" component={LoginPage} />
              <Route exact path="/subscriptions" component={LoginPage} />
              <Route exact path="/events/:id/pay-later" component={LoginPage} />
              <Route exact path="/subscriptions/:subscriptionId" component={LoginPage} />
              <Route exact path="/bookings/:reservationId" component={LoginPage} />
              <Route exact path="/booking-modifiers" component={LoginPage} />
              <Route exact path="/bookings/requests" component={LoginPage} />
              <Route exact path="/facilities" component={LoginPage} />
              <Route exact path="/company-settings" component={LoginPage} />
              <Route exact path="/calendar" component={LoginPage} />
              <Route exact path="/dashboard" component={LoginPage} />
              <Route exact path="/analytics" component={LoginPage} />
              <Route exact path="/event-analytics" component={LoginPage} />
              <Route exact path="/users" component={LoginPage} />
              <Route exact path="/users/:id" component={LoginPage} />
              <Route exact path="/leagues/:id" component={LoginPage} />
              <Route exact path="/memberships" component={LoginPage} />
              <Route exact path="/memberships/:membershipId" component={LoginPage} />
              <Route exact path="/memberships/:membershipId/user/:userId" component={LoginPage} />
              <Route exact path="/priorityaccess" component={LoginPage} />
              <Route exact path="/closures" component={LoginPage} />
              <Route exact path="/pricing" component={LoginPage} />
              <Route exact path="/payments" component={LoginPage} />
              <Route exact path="/payments/:orderId" component={LoginPage} />
              <Route exact path="/facilities/edit/:facilityid" component={LoginPage} />
              <Route exact path="/team-members" component={LoginPage} />
              <Route exact path="/agreement/:billingId" component={LoginPage} />
              <Route exact path="/agreement/:billingId/processing" component={LoginPage} />
              <Route exact path="/payouts" component={LoginPage} />
              <Route exact path="/help" component={LoginPage} />
              <Route exact path="/calculator" component={LoginPage} />
              <Route exact path="/schedules" component={LoginPage} />
              <Route exact path="/pricing" component={LoginPage} />
              <Route exact path="/events" component={LoginPage} />
              <Route exact path="/profile" component={LoginPage} />
              <Route exact path="/products" component={LoginPage} />
              <Route exact path="/products/:id" component={LoginPage} />
              <Route exact path="/point-of-sale" component={LoginPage} />
              <Route exact path="/point-of-sale-order-history" component={LoginPage} />
              <Route exact path="/point-of-sale/:reservationId" component={LoginPage} />
              <Route exact path="/messages" component={LoginPage} />
              <Route exact path="/messages/:id" component={LoginPage} />
              <Route exact path="/booking-modifiers" component={LoginPage} />
              <Route exact path="/utils" component={LoginPage} />
              <Route exact path="/associates" component={LoginPage} />
              <Route exact path="/referral" component={LoginPage} />
              <Route exact path="/unlock" component={LoginPage} />
              <Route exact path="/map" component={LoginPage} />
              <Route exact path="/verify" component={LoginPage} />
              <Route exact path="/billing" component={LoginPage} />
              <Route exact path="/companies" component={LoginPage} />
              <Route exact path="/library" component={LoginPage} />
            </Switch>
          </main>
        </div>
      );
    } else if (
      billingInfo?.required
      && role !== 'PITCHBOOKING_INTERNAL'
    ) {
      appView = (
        <div>
          <MenuDrawer
            currentCompany={companies.companyInfo}
            appBarOnly
            logoutTrigger={() => this.logout()}
            changePasswordTrigger={() => this.handleChangePassword()}
            currentUser={currentUser}
            menuDrawerOpen={false}
            updateUserCompany={(companyId) => this.updateUserCompany(companyId)}
          />
          <Route component={Billing} />
        </div>
      );
    } else if (
      companies?.companyInfo?.stripeId === null
      && companies?.companyInfo?.status === 'NEW_PENDING_APPROVAL'
      && role !== 'PITCHBOOKING_INTERNAL'
    ) {
      appView = (
        <>
          <MenuDrawer
            currentCompany={companies.companyInfo}
            appBarOnly
            logoutTrigger={() => this.logout()}
            changePasswordTrigger={() => this.handleChangePassword()}
            currentUser={currentUser}
            menuDrawerOpen={false}
            updateUserCompany={(companyId) => this.updateUserCompany(companyId)}
          />
          <div style={{ display: 'flex', marginTop: '4.5%' }}>
            <Route component={Welcome} />
          </div>
        </>
      );
    } else if (
      companies?.companyInfo?.stripeId === null
      && companies?.companyInfo?.stripeConnectRequired
      && role !== 'PITCHBOOKING_INTERNAL'
    ) {
      appView = (
        <>
          <MenuDrawer
            currentCompany={companies.companyInfo}
            appBarOnly
            logoutTrigger={() => this.logout()}
            changePasswordTrigger={() => this.handleChangePassword()}
            currentUser={currentUser}
            menuDrawerOpen={false}
            updateUserCompany={(companyId) => this.updateUserCompany(companyId)}
          />
          <div style={{ display: 'flex', marginTop: '4.5%' }}>
            <Route component={StripeRequired} />
          </div>
        </>
      );
    } else if (
      companies?.companyInfo?.status === 'ACCOUNT_MANAGER_UNASSIGNED'
      && role !== 'PITCHBOOKING_INTERNAL'
    ) {
      appView = (
        <>
          <MenuDrawer
            currentCompany={companies.companyInfo}
            appBarOnly
            logoutTrigger={() => this.logout()}
            changePasswordTrigger={() => this.handleChangePassword()}
            currentUser={currentUser}
            menuDrawerOpen={false}
            updateUserCompany={(companyId) => this.updateUserCompany(companyId)}
          />
          <div style={{ display: 'flex', marginTop: '4.5%', flexDirection: 'column' }}>
            <Route component={NextSteps} />
            <Route path="/product/:type" component={Product} />
          </div>
        </>
      );
    } else {
      const { companyInformationOpen } = this.state;
      const { location, companies } = this.props;
      const {
        needsBilling, doubleBookingAlert, needsStripeVerification,
        warningMessageForAdmins, warningMessageForAdminsPersist,
      } = companies.companyInfo;
      const { pathname } = location;
      let menuDrawerOpen = true;
      const isMobile = window.innerWidth < 768;
      if (pathname.startsWith('/billing') || pathname.startsWith('/calendar') || pathname.startsWith('/bookings') || isMobile) {
        menuDrawerOpen = false;
      }
      appView = (
        <div>
          <MenuDrawer
            currentCompany={companies.companyInfo}
            warningMessageForAdmins={warningMessageForAdmins}
            warningMessageForAdminsPersist={warningMessageForAdminsPersist}
            logoutTrigger={() => this.logout()}
            changePasswordTrigger={() => this.handleChangePassword()}
            currentUser={currentUser}
            menuDrawerOpen={menuDrawerOpen}
            hasPendingMembershipRequests={companies.companyInfo?.hasPendingMembershipRequests}
            updateUserCompany={(companyId) => this.updateUserCompany(companyId)}
          />
          <CompanyUpdateModal
            open={(needsBilling && companyInformationOpen)
              || (doubleBookingAlert && companyInformationOpen)
              || (needsStripeVerification && companyInformationOpen)}
            close={
              (needsBilling,
                doubleBookingAlert,
                needsStripeVerification) => this.handleCompanyInformationClose(
                needsBilling, doubleBookingAlert, needsStripeVerification,
              )
            }
            needsBilling={needsBilling}
            doubleBookingAlert={doubleBookingAlert}
            needsStripeVerification={needsStripeVerification}
            changePasswordTrigger={(
              newPassword,
              existingPassword,
            ) => doUpdateUserPassword(newPassword, existingPassword)}
          />
          <ReservationCreationErrorDialog />
        </div>
      );
    }
    return appView;
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { currentUser, userError } = users;
  const { companies } = state;
  return {
    users,
    currentUser,
    companies,
    userError,
  };
}

const mapDispatchToProps = (dispatch) => ({
  doUpdateSessionClient: (client) => dispatch(updateSessionClient(client)),
  updateUserSelectedCompany: (companyId) => dispatch(actions.updateUserSelectedCompany(companyId)),
  updateCurrentUser: (user) => dispatch(actions.updateCurrentUser(user)),
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getCurrentUsersCompanies: (userId) => dispatch(companyActions.getCurrentUsersCompanies(userId)),
  updateCompanyInfo: (company) => dispatch(companyActions.updateCompanyInfo(company)),
  requestStripeAuthorisation: (stripeAuthorisationCode) => dispatch(
    companyActions.requestStripeAuthorisation(stripeAuthorisationCode),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
