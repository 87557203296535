import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import moment from 'moment';
import {
  updateSelectedPitchSplit,
} from '@pitchbooking-dev/pb-shared/lib/reducers/bookingReducer';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import * as actions from '../reducers/subscriptionsReducer';
import * as accessRestrictionActions from '../reducers/accessRestrictionsReducer';
import * as bookingActions from '../reducers/bookingsReducer';
import * as calendarActions from '../reducers/calendarReducer';
import {
  getSubscriptionsService,
  getSubscriptionsReservationsService,
  createSubscriptionService,
  deleteSubscriptionServices,
  getSubscriptionService,
  createExclusionService,
  deleteExclusionService,
  pauseSubscriptionsService,
  editSubscriptionService,
  addSubscriptionNoteService,
  createExclusionAndReservationService,
} from '../services/subscriptionsServices';
import { handleSagaError } from './helperSaga';

function* getSubscriptionsSaga(companyId, action) {
  try {
    const { dates } = action;
    let{ status } = action;
    if (!status) {
      status = 'CURRENT';
    }
    const response = yield call(getSubscriptionsService, companyId, dates, status);
    if (!response.error) {
      yield put(actions.updateSubscriptions(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getSubscriptionsSaga');
  }
}
function* getSubscriptionsReservationsSaga(companyId, action) {
  try {
    const { dates } = action;
    const response = yield call(getSubscriptionsReservationsService, companyId, dates);
    if (!response.error) {
      yield put(bookingActions.updateSubscriptionBookings(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getSubscriptionsReservationsSaga');
  }
}

function* getSubscriptionSaga(companyId, action) {
  try {
    const { id } = action;
    const response = yield call(getSubscriptionService, companyId, id);
    if (!response.error) {
      yield put(actions.updateSubscription(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getSubscriptionSaga');
  }
}

function* addExclusionSaga(companyId) {
  try {
    const state = yield select();
    const { exclusions } = state.accessRestrictions.newAccessRestriction;
    const subscriptionId = state.subscriptions.subscriptionView.id;
    const { id } = state.subscriptions.subscriptionView.accessRestriction;
    const response = yield call(createExclusionService, companyId, id, exclusions);
    if (!response.error) {
      yield put(accessRestrictionActions.updateAccessRestrictionCreation({ exclusions: [] }));
      yield put(actions.getSubscription(subscriptionId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addExclusionSaga');
  }
}

function* deleteExclusionSaga(companyId, action) {
  try {
    const state = yield select();
    const subscriptionId = state.subscriptions.subscriptionView.id;
    const { id } = action;
    const response = yield call(deleteExclusionService, companyId, id, subscriptionId);
    if (!response.error) {
      yield put(actions.getSubscription(subscriptionId));
    } else { throw response; }
  } catch (error) {
    yield put(actions.deleteExclusionFailure(error.error));
    yield call(handleSagaError, error, 'deleteExclusionSaga');
  }
}

// CREATE
function* createSubscriptionSaga(companyId, action) {
  const state = yield select();
  const { checkForConflicts } = action;
  const { subscription, applyExclusions, conflicts } = state.subscriptions;
  let conflictingAllocationsData = [];
  let conflictingSubscriptionData = [];
  let conflictingAccessRestrictionsData = [];
  if (conflicts) {
    conflictingAllocationsData = conflicts.conflictingAllocations;
    conflictingSubscriptionData = conflicts.conflictingFacilitySubscriptions;
    conflictingAccessRestrictionsData = conflicts.conflictingAccessRestrictions;
  }
  const startTime = moment(subscription.startTimeFormatted).startOf('minute').format('HH:mm:ss');
  const endTime = moment(subscription.endTimeFormatted).endOf('minute').format('HH:mm:ss');
  const stripeAmount = subscription.amount * 100;
  const userDetails = state.subscriptions.userDetailsForSubscription;
  if (userDetails.id?.length === 0) {
    delete userDetails.id;
  }
  const subscriptionData = {
    ...subscription,
    startTime,
    endTime,
    amount: stripeAmount.toFixed(0),
    userDetails,
    applyExclusions,
    conflictingAllocationsData,
    conflictingSubscriptionData,
    conflictingAccessRestrictionsData,
  };

  try {
    const response = yield call(
      createSubscriptionService,
      companyId,
      subscriptionData,
      checkForConflicts,
    );

    if (checkForConflicts) {
      const createSubscriptionResponseData = response.data;
      if (createSubscriptionResponseData && createSubscriptionResponseData.conflicts) {
        yield put(
          actions.alertSubscriptionCreationConflicts(createSubscriptionResponseData.conflicts),
        );
        return;
      }
      return;
    }

    if (!response.error) {
      yield put(actions.resetSubscriptionStore());
      yield put(actions.resetApplyExclusions());
      yield put(updateSelectedPitchSplit(1));
      yield put(actions.succeedSubscriptionCreation());
      yield put(actions.getSubscriptions());

      if (window.location.pathname === '/calendar') {
        yield put(calendarActions.getCalendarUsage());
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createSubscriptionSaga');
  }
}

function* deleteSubscriptionsSaga(action) {
  try {
    const response = yield call(
      deleteSubscriptionServices,
      action.subscriptionId,
    );
    if (!response.error) {
      yield put(actions.getSubscriptions());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deleteSubscriptionsSaga');
  }
}

function* editSubscriptionSaga(companyId, action) {
  const state = yield select();
  const { editedSubscription } = state.subscriptions;
  try {
    const response = yield call(
      editSubscriptionService,
      companyId,
      action.subscriptionId,
      editedSubscription,
    );
    if (!response.error) {
      yield put(actions.resetSubscriptionStore());
      yield put(actions.editSubscriptionSuccess());
    } else { throw response; }
  } catch (error) {
    yield put(actions.resetSubscriptionStore());
    yield put(actions.editSubscriptionFailure(error.error, error.data));
    yield call(handleSagaError, error, 'editSubscriptionSaga');
  }
}

function* pauseSubscriptionsSaga(companyId, action) {
  const { subscriptionIds, resumesAt } = action;
  const reqBody = {
    subscriptionIds,
    resumesAt,
  };
  try {
    const response = yield call(
      pauseSubscriptionsService,
      companyId,
      reqBody,
    );
    if (!response.error) {
      yield put(actions.succeedSubscriptionPause(response.data));
    } else { throw response; }
  } catch (error) {
    yield put(actions.failSubscriptionPause(error.data, error.error));
    yield call(handleSagaError, error, 'pauseSubscriptionsSaga');
  }
}

function* addSubscriptionNoteSaga(companyId, action) {
  const { subscriptionId, note, date } = action;
  try {
    const response = yield call(
      addSubscriptionNoteService,
      companyId,
      subscriptionId,
      note,
      date,
    );
    if (!response.error) {
      yield put(actions.getSubscription(subscriptionId));
      yield put(sessionActions.updateSuccessfulSnackbar('Note added to subscription', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addSubscriptionNoteSaga');
  }
}

function* excludeSlotAndCreateReservationSaga(companyId, action) {
  const { subscriptionSlotToExclude, newSlot } = action;
  const reqBody = {
    newSlot,
    subscriptionSlotToExclude,
  };
  try {
    const response = yield call(
      createExclusionAndReservationService,
      companyId,
      reqBody,
    );
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Subscription slot successfully edited', true));
      yield put(bookingActions.updateBookingsEditSuccess({
        message: 'Booking Updated Successfully',
        facility: response.data?.allocations[0]?.facility?.name,
        date: moment(response.data?.allocations[0]?.startTime).tz(response.data?.allocations[0]?.facility?.timezone).format('DD-MMM-YYYY'),
        startTime: moment(response.data?.allocations[0]?.startTime).tz(response.data?.allocations[0]?.facility?.timezone).format('HH:mm'),
        endTime: moment(response.data?.allocations[0]?.endTime).tz(response.data?.allocations[0]?.facility?.timezone).add(1, 's').format('HH:mm'),
      }));
      yield put(calendarActions.getCalendarUsage());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'excludeSlotAndCreateReservationSaga');
  }
}

export function* getSubscriptionsWatcher(companyId) {
  yield takeEvery(actions.UPDATE_SUBSCRIPTION_STATUS, getSubscriptionsSaga, companyId);
  yield takeEvery(actions.SUBSCRIPTIONS_REQUESTED, getSubscriptionsSaga, companyId);
}
export function* getSubscriptionsReservationsWatcher(companyId) {
  yield takeEvery(bookingActions.GET_SUBSCRIPTION_BOOKINGS,
    getSubscriptionsReservationsSaga, companyId);
}
export function* getSubscriptionWatcher(companyId) {
  yield takeEvery(actions.SUBSCRIPTION_REQUESTED, getSubscriptionSaga, companyId);
}
export function* addExclusionWatcher(companyId) {
  yield takeEvery(actions.EXCLUSION_ADD_REQUESTED, addExclusionSaga, companyId);
}
export function* deleteExclusionWatcher(companyId) {
  yield takeEvery(actions.EXCLUSION_DELETE_REQUESTED, deleteExclusionSaga, companyId);
}
export function* deleteSubscriptionsWatcher() {
  yield takeEvery(actions.DELETE_SUBSCRIPTION_REQUESTED, deleteSubscriptionsSaga);
}
export function* pauseSubscriptionsWatcher(companyId) {
  yield takeEvery(actions.PAUSE_SUBSCRIPTIONS_REQUESTED, pauseSubscriptionsSaga, companyId);
}
export function* createSubscriptionWatcher(companyId) {
  yield takeEvery(actions.SUBSCRIPTION_CREATION_REQUESTED, createSubscriptionSaga, companyId);
}
export function* editSubscriptionWatcher(companyId) {
  yield takeEvery(actions.EDIT_SUBSCRIPTION_REQUESTED, editSubscriptionSaga, companyId);
}
export function* addSubscriptionNoteWatcher(companyId) {
  yield takeEvery(actions.ADD_NOTE_TO_SUBSCRIPTION_REQUESTED, addSubscriptionNoteSaga, companyId);
}

export function* excludeSlotAndCreateReservationWatcher(companyId) {
  yield takeEvery(actions.EXCLUDE_SLOT_AND_CREATE_RESERVATION,
    excludeSlotAndCreateReservationSaga, companyId);
}
