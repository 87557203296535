/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SearchIcon from '@material-ui/icons/Search';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ClearIcon from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Alert from '@material-ui/lab/Alert';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Card } from '@pitchbooking-dev/pb-shared/lib/components/common';
import * as actions from '../../reducers/referralsReducer';
import styles from './Referral.module.css';

const validation = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  organisation: yup.string().required('Organisation is required'),
});

const Referral = (props) => {
  const { referrals, createReferral } = props;
  const { companyInfo } = useSelector((state) => state.companies);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dialCode: companyInfo.dialcode || '44',
    organisation: '',
  };

  useEffect(() => {
    const { getReferrals } = props;
    getReferrals();
  }, []);

  return (
    <div className={styles.referral__wrapper}>
      <Card>
        <Card.Body className={styles.referral__information}>

          {companyInfo.referralAgreement ? (
            <>
              <Typography variant="body1">
                Thank you for visiting our Referral Page!
                You can get started right now by referring potential customers in the box below.
                Upon a successful referral, you will receive the following incentives:
              </Typography>
              <div className={styles.referral__separator} />
              <Typography variant="body1">
                {companyInfo.referralAgreement}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">
              Thank you for visiting our Referral Page!
              You can get started right now by referring potential customers in the box below.
              The exact incentives that you will receive upon your referral
              signing up will need to be confirmed with your Account Manager.
              Please get in touch with your Account Manager to discuss this further.
            </Typography>
          )}

        </Card.Body>
      </Card>

      <Card className={styles.referral__form}>
        <Card.Body>
          Referral Form
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              createReferral(values);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5em',
                  margin: '1em 0',
                }}
              >
                <PBInput
                  id="firstName"
                  type="text"
                  label="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  isError={touched.firstName && Boolean(errors.firstName)}
                  errorMessage={touched.firstName && errors.firstName}
                />

                <PBInput
                  id="lastName"
                  type="text"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  isError={touched.lastName && Boolean(errors.lastName)}
                  errorMessage={touched.lastName && errors.lastName}
                />

                <PBInput
                  id="phone"
                  type="phone"
                  value={{ phone: values.phone, dialCode: values.dialCode }}
                  onChange={handleChange}
                  isError={touched.phone && Boolean(errors.phone)}
                  errorMessage={touched.phone && errors.phone}
                />

                <PBInput
                  id="organisation"
                  type="text"
                  label="Organisation"
                  value={values.organisation}
                  onChange={handleChange}
                  isError={touched.organisation && Boolean(errors.organisation)}
                  errorMessage={touched.organisation && errors.organisation}
                />

                <PBInput
                  id="email"
                  type="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  isError={touched.email && Boolean(errors.email)}
                  errorMessage={touched.email && errors.email}
                />

                <Button
                  id="signup button"
                  type="submit"
                  className="signup-button"
                  variant="contained"
                >
                  Refer User
                </Button>
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <MaterialTable
        className="referral__history"
        data={referrals}
        title="Your Referrals"
        icons={{
          NextPage: () => <ChevronRightIcon />,
          PreviousPage: () => <ChevronLeftIcon />,
          Search: () => <SearchIcon />,
          Export: () => <SaveAltIcon />,
          ResetSearch: () => <ClearIcon />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          Filter: () => <SearchIcon />,
        }}
        columns={[
          {
            title: 'First Name',
            field: 'firstName',
          },
          {
            title: 'Last Name',
            field: 'lastName',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Phone',
            field: 'phone',
            render: (row) => (
              <div>
                {row.dialCode}
                {' '}
                {row.phone}
              </div>
            ),
          },
          {
            title: 'Organisation',
            field: 'organisation',
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            field: 'alreadyReferred',
            filtering: false,
            hidden: referrals.every((r) => r.alreadyReferred === false),
            render: (row) => (
              <ConditionallyVisible condition={row.alreadyReferred}>
                <Alert
                  id="alert"
                  severity="info"
                >
                  Already Referred
                </Alert>
              </ConditionallyVisible>
            ),
          },
        ]}
        options={{
          emptyRowsWhenPaging: false,
          tableLayout: 'auto',
          pageSize: 50,
          pageSizeOptions: [10, 25, 50, 100],
          showFirstLastPageButtons: false,
        }}
      />
    </div>
  );
};

function mapStateToProps(state) {
  let {
    referrals,
  } = state;
  referrals = referrals.referrals;
  return {
    referrals,
  };
}

Referral.propTypes = {
  getReferrals: PropTypes.func.isRequired,
  referrals: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createReferral: PropTypes.func.isRequired,

};

const mapDispatchToProps = (dispatch) => ({
  getReferrals: () => dispatch(actions.getReferrals()),
  createReferral: (values) => dispatch(actions.createReferral(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
