import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Paper,
  Card,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import { requestSitesWithFacilitiesRetrieval } from '../../reducers/sitesReducer';
import { getCompanyAddons, createAddonReservation } from '../../reducers/addonsReducer';
import UserSelection from './UserSelection';
import OrderTable from './OrderTable';
import { PosPaymentDialog } from './PosPaymentDialog';

const Pos = () => {
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;
  const [selectedSite, setSelectedSite] = useState(null);
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [order, setOrder] = useState([]);
  const sites = useSelector((state) => state.sites.companySites);
  const categories = useSelector((state) => state.addons.categories);
  const allAddons = useSelector((state) => state.addons.addons);
  const currency = useSelector((state) => state.companies.companyInfo.currency);
  const [basketHidden, setBasketHidden] = useState(isMobile);
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const { managerAccess } = currentUser;
  const [selectedUser, setSelectedUser] = useState(null);
  const hasPOSItems = allAddons?.filter((addon) => addon.isStandalone).length > 0;
  const preferredPosSite = JSON.parse(localStorage.getItem('preferredPosSite'));
  const companyId = localStorage.getItem('selectedCompany');

  useEffect(() => {
    const sameCompany = companyId === preferredPosSite?.companyId;
    const hasSiteAccess = availableSites.length > 0
      ? availableSites.map((site) => site.id).includes(preferredPosSite?.id)
      : true;

    if (preferredPosSite && sameCompany && hasSiteAccess) {
      setSelectedSite(preferredPosSite);
    } else if (availableSites.length === 1) {
      setSelectedSite(availableSites[0]);
      localStorage.setItem('preferredPosSite', JSON.stringify(availableSites[0]));
    } else {
      localStorage.removeItem('preferredPosSite');
      setSelectedSite(null);
    }
  }, [availableSites]);

  const handleOrderChange = (existingOrder, changeType, addon) => {
    if (changeType === 'REMOVE') {
      const newOrder = existingOrder.map((orderItem) => {
        if (orderItem.id === addon.id) {
          if (orderItem.quantity === 1) {
            return null;
          }

          return {
            ...orderItem,
            quantity: orderItem.quantity - 1,
            price: orderItem.price - addon.priceModifiers[0]?.value,
          };
        }
        return orderItem;
      }).filter((orderItem) => orderItem !== null);
      setOrder(newOrder);
    }

    if (changeType === 'ADD') {
      const newOrder = existingOrder.map((orderItem) => {
        if (orderItem.id === addon.id) {
          return {
            ...orderItem,
            quantity: orderItem.quantity + 1,
            price: orderItem.price + addon.priceModifiers[0]?.value,
          };
        }
        return orderItem;
      });
      setOrder(newOrder);
    }
  };

  const updateOrder = (addon) => {
    const existingAddon = order.find((orderItem) => orderItem.id === addon.id);
    if (existingAddon) {
      // Update quantity of the existing addon
      const newOrder = order.map((orderItem) => {
        if (orderItem.id === addon.id) {
          return {
            ...orderItem,
            quantity: orderItem.quantity + 1,
            price: orderItem.price + addon.priceModifiers[0]?.value,
          };
        }
        return orderItem;
      });
      setOrder(newOrder);
    } else {
      // Add new addon to the order with initial quantity
      setOrder([...order, { ...addon, quantity: 1, price: addon.priceModifiers[0].value }]);
    }
  };

  useEffect(() => {
    dispatch(requestSitesWithFacilitiesRetrieval());
    dispatch(getCompanyAddons(null, null, true));
    const siteAccess = managerAccess.map((ma) => ma.siteId).filter((siteId) => siteId !== null);
    if (siteAccess.length === 1) {
      setSelectedSite(siteAccess[0]);
      setAvailableSites(siteAccess);
    }
  }, []);
  useEffect(() => {
    if (sites.length === 1) { // If there is only one site, set it as the selected site
      setSelectedSite(sites[0]);
      setAvailableSites(sites);
    }
  }, [sites]);

  useEffect(() => {
    if (allAddons && selectedSite !== null && selectedCategory === 'UNCATEGORISED') {
      const filteredAddons = allAddons.filter(
        (addon) => addon.categoryId === null
          && (addon.siteId === selectedSite?.id || addon.siteId === null)
          && addon.children.length === 0,
      );
      setSelectedAddons(filteredAddons);
    } else if (allAddons && selectedSite !== null && selectedCategory !== null) {
      const filteredAddons = allAddons.filter((addon) => addon.categoryId === selectedCategory?.id
        && (addon.siteId === selectedSite?.id || addon.siteId === null)
        && addon.children.length === 0);
      setSelectedAddons(filteredAddons);
    }
  }, [selectedCategory, allAddons]);

  const isProductAvailable = (addon) => {
    if (addon.trackStock === false) return true;
    const currentlySelected = order.find((orderItem) => orderItem.id === addon?.id)?.quantity;
    const currentStock = addon?.currentStock;
    if (currentlySelected) {
      return currentStock - currentlySelected > 0;
    }
    return currentStock > 0;
  };

  const cardStyle = (addon) => {
    let isAvailable = true;
    let lineHeight = '200px';
    let padding = 0;
    if (addon) {
      isAvailable = isProductAvailable(addon);
      lineHeight = 1;
      padding = 30;
    }

    return {
      padding,
      backgroundColor: isAvailable ? '#fff' : '#f9f9f9',
      borderRadius: 5,
      color: isAvailable ? '#6699ff' : '#ccc',
      fontWeight: 'bold',
      border: isAvailable ? '2px solid #6699ff' : '2px solid #ccc',
      cursor: isAvailable ? 'pointer' : 'auto',
      minWidth: 200,
      minHeight: 250,
      maxWidth: 200,
      maxHeight: 250,
      textAlign: 'center',
      lineHeight,
    };
  };
  const TillMenu = () => {
  // STEP 1 Select a site
    if (selectedSite === null && sites.length > 0) {
      return (
        <div>
          <h1>Select Your Site</h1>
          <div style={{
            display: 'flex', gap: 10, justifyContent: isMobile ? 'center' : 'flex-start', flexWrap: 'wrap', marginTop: 10,
          }}
          >
            {sites.map((site) => (
              <Card
                style={cardStyle()}
                key={site.id}
                onClick={() => {
                  localStorage.setItem('preferredPosSite', JSON.stringify(site));
                  setSelectedSite(site);
                }}
              >
                <h3>{site.name}</h3>
              </Card>
            ))}
          </div>
        </div>
      );
    }
    // STEP 2 Select a category
    if (selectedSite !== null && selectedCategory === null) {
      return (
        <div>
          {availableSites.length !== 1 && (
          <Button variant="contained" color="secondary" onClick={() => setSelectedSite(null)}>Back</Button>
          )}
          <h1>Select a category</h1>
          <div style={{
            display: 'flex', gap: 10, justifyContent: isMobile ? 'center' : 'flex-start', flexWrap: 'wrap', marginTop: 10,
          }}
          >
            {categories.map((category) => (
              <Card
                style={cardStyle()}
                key={category.id}
                onClick={() => setSelectedCategory(category)}
              >
                <h3>{category.name}</h3>
              </Card>
            ))}
            <Card
              style={cardStyle()}
              onClick={() => setSelectedCategory('UNCATEGORISED')}
            >
              <h3>Uncategorised</h3>
            </Card>
          </div>
        </div>
      );
    }
    // STEP 3 Select Products
    if (selectedSite !== null && selectedCategory !== null) {
      return (
        <div>
          <Button variant="contained" color="secondary" onClick={() => setSelectedCategory(null)}>Back</Button>
          <h1>Products</h1>
          <div>
            <h2>{selectedCategory?.name}</h2>
          </div>

          <div style={{
            display: 'flex', gap: 10, justifyContent: isMobile ? 'center' : 'flex-start', flexWrap: 'wrap', marginTop: 10,
          }}
          >
            {selectedAddons && selectedAddons.map((addon) => {
              const isAvailable = isProductAvailable(addon);
              return (
                <Card
                  style={cardStyle(addon)}
                  key={addon.id}
                  onClick={() => {
                    if (isAvailable) {
                      updateOrder(addon);
                    }
                  }}
                >
                  {addon.photoUrl ? (
                    <img
                      src={addon.photoUrl}
                      alt={addon.name}
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: 5,
                        marginBottom: 15,
                      }}
                    />
                  )
                    : (
                      <img
                        src="https://placehold.co/100x100"
                        alt={addon.name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: 5,
                          marginBottom: 15,
                        }}
                      />
                    )}
                  {addon.name}
                  {' '}
                  {addon.trackStock && addon.currentStock === 0 ? '(Out of Stock)' : ''}
                </Card>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };
  let basketStyle = {
    padding: 10, margin: 10, display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', height: 'calc(85vh - 20px)',
  };
  let tableStyle = {
    borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '0.25rem',
  };

  if (isMobile) {
    basketStyle = {
      display: 'flex', flexDirection: 'column-reverse', justifyContent: 'space-between', height: 'calc(100vh - 20px)',
    };
    tableStyle = {
      position: 'fixed', bottom: 0, borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%', padding: 10,
    };
  }
  return (
    <div style={basketStyle}>
      <div style={{ width: 'fit-content' }}>
        {basketHidden ? (
          <Paper
            style={tableStyle}
            component={Paper}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontWeight: 'bold', textAlign: 'center', marginLeft: '10%' }}>
                {order.reduce((acc, addon) => acc + (addon.quantity), 0)}
                {' items'}
              </div>
              <Button variant="contained" color="secondary" onClick={() => setBasketHidden(false)}>View Order</Button>
            </div>
          </Paper>
        ) : (
          <Paper
            style={tableStyle}
            component={Paper}
          >
            {isMobile && (
            <div style={{ float: 'right', padding: 5 }}>
              <CloseIcon onClick={() => setBasketHidden(true)} />
              <br />
            </div>
            )}
            <div style={{
              display: 'flex', gap: 5, margin: 10, padding: 10,
            }}
            >
              <Button variant="contained" color="error" style={{ width: 180 }} onClick={() => setOrder([])}>Clear</Button>
              <PosPaymentDialog
                disabled={order.length === 0}
                selectedUser={selectedUser}
                createReservations={
                  (paymentStatus, isIgnoringStock = false) => dispatch(
                    createAddonReservation(order, selectedUser, paymentStatus, isIgnoringStock),
                  )
                }
              />
            </div>
            <div style={{
              display: 'flex', gap: 5, margin: 10, padding: 10,
            }}
            >
              {selectedUser === null ? (
                <UserSelection userSelected={(user) => setSelectedUser(user)} />
              ) : (
                <div>
                  <p>Selected Customer: </p>
                  <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    <p>
                      {selectedUser.firstName}
                      {' '}
                      {selectedUser.lastName}
                    </p>
                    <Button variant="outlined" style={{ height: '25px' }} color="error" onClick={() => setSelectedUser(null)}>Reset</Button>
                  </div>
                </div>
              )}
            </div>
            <OrderTable
              order={order}
              handleOrderChange={handleOrderChange}
              isProductAvailable={isProductAvailable}
              currency={currency}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: '1rem',
                justifyContent: 'flex-end',
                gap: '0.5rem',
              }}
            >
              <Select
                options={sites.map((site) => ({ label: site.name, value: site.id }))}
                onChange={(e) => {
                  setSelectedSite(sites.filter((site) => site.id === e.value)[0]);
                  localStorage.setItem('preferredPosSite', JSON.stringify(sites.filter((site) => site.id === e.value)[0]));
                }}
                value={preferredPosSite?.id
                  ? { label: preferredPosSite.name, value: preferredPosSite.id }
                  : null}
                placeholder="Change Site"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                menuPlacement="top"
              />
            </div>
          </Paper>
        )}
      </div>
      <Paper
        style={{
          borderTopLeftRadius: '10px',
          overflowX: 'hidden',
          padding: 20,
          width: '100%',
          backgroundColor: '#FAFAFA',
        }}
        component={Paper}
      >
        {hasPOSItems ? (
          <TillMenu style={{ marginTop: 10 }} />
        ) : (
          <div>
            <h1>No items available for sale</h1>
            <p>Please add some items to the Products dashboard</p>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Pos;
