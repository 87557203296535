import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
  getAddonsService,
  getCompanyAddonsService,
  createCompanyAddonsService,
  deleteFacilityAddonsService,
  createFacilityAddonsService,
  requestAddonUpdateService,
  deleteAddonsService,
  createCompanyCategoryService,
  deleteCategoryService,
  getAddonService,
  createStockTransactionService,
  createCompanyAddonsReservationService,
  getPOSReservationsService,
} from '../services/addonsServices';
import * as addonsActions from '../reducers/addonsReducer';
import * as facilityActions from '../reducers/facilitiesReducer';
import { handleSagaError } from './helperSaga';

function* getCompanyAddonsSaga(companyId, action) {
  const { facilityId, eventId, includeSubAddons } = action;
  try {
    const response = yield call(
      getCompanyAddonsService, companyId, facilityId, eventId, includeSubAddons,
    );
    if (!response.error) {
      yield put(addonsActions.updateCompanyAddons(response.data?.addon));
      yield put(addonsActions.updateCompanyCategories(response.data?.categories));
    } else { throw response; }
  } catch (error) {
    console.log('getCompanyAddonsSaga error', error);
  }
}

function* createAddonsReservationSaga(companyId, action) {
  const {
    items, user, paymentStatus, isIgnoringStock,
  } = action;
  const reqBody = {
    items,
    user,
    paymentStatus,
    isIgnoringStock,
  };
  try {
    const response = yield call(createCompanyAddonsReservationService, companyId, reqBody);
    if (!response.error) {
      const { data } = response;
      if (paymentStatus) {
        yield put(addonsActions.getCompanyAddons());
        yield (put(push('/point-of-sale')));
        window.location.reload();
        yield put(sessionActions.updateSuccessfulSnackbar('Payment Successful.', true));
      } else {
        yield put(addonsActions.succeedAddonReservationCreation(data));
      }
    } else { throw response; }
  } catch (error) {
    console.log('createAddonsReservationSaga error', error);
  }
}

function* createCompanyAddonSaga(companyId, action) {
  const { addon } = action;
  try {
    const response = yield call(createCompanyAddonsService, companyId, addon);
    if (!response.error) {
      yield put(addonsActions.getCompanyAddons());
    } else { throw response; }
  } catch (error) {
    console.log('createCompanyAddonSaga error', error);
  }
}

function* createFacilityAddonsSaga(companyId, action) {
  const state = yield select();
  const { selectedFacility } = state.facilities;
  const { facilityAddons } = action;
  const body = {
    facilityAddons,
  };
  if (companyId) {
    try {
      const response = yield call(createFacilityAddonsService, companyId, body);
      if (!response.error) {
        yield put(facilityActions.requestFacilityRetrieval(selectedFacility.id));
        yield put(sessionActions.updateSuccessfulSnackbar('Facility addon creation successful.', true));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'createFacilityAddonsSaga');
      yield put(sessionActions.updateErrorSnackbar(`Facility addon deletion failed : ${error.error}`, true));
      yield put(facilityActions.createFacilityFailed(error.error));
    }
  }
}

function* deleteFacilityAddonsSaga(companyId, action) {
  const state = yield select();
  const { selectedFacility } = state.facilities;
  const { facilityAddons } = action;
  const body = {
    facilityAddons,
  };
  try {
    const response = yield call(deleteFacilityAddonsService, companyId, body);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Facility addon successfully deleted.', true));
      yield put(facilityActions.requestFacilityRetrieval(selectedFacility.id));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deleteFacilityAddonsSaga');
    yield put(sessionActions.updateErrorSnackbar(`Facility addon deletion failed : ${error.error}`, true));
  }
}

function* getAddonsSaga(companyId) {
  try {
    const response = yield call(getAddonsService, companyId);
    if (!response.error) {
      const formattedResponse = [];
      response.data?.addon.forEach((addon) => {
        formattedResponse.push({ ...addon, value: false });
      });
      yield put(addonsActions.succeedAddonsRetrieval(formattedResponse));
      yield put(addonsActions.updateCompanyCategories(response.data?.categories));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getAddonsSaga');
  }
}

function* deleteAddonSaga(companyId, action) {
  try {
    const { addonId } = action;
    const response = yield call(deleteAddonsService, companyId, addonId);
    if (!response.error) {
      yield put(addonsActions.requestFacilityAddons());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getAddonsSaga');
  }
}

function* createCategorySaga(companyId, action) {
  const { category } = action;
  try {
    const response = yield call(createCompanyCategoryService, companyId, category);
    if (!response.error) {
      yield put(addonsActions.getCompanyAddons());
    } else { throw response; }
  } catch (error) {
    console.log('createCategorySaga error', error);
  }
}

function* deleteCategorySaga(companyId, action) {
  const { categoryId } = action;
  try {
    const response = yield call(deleteCategoryService, companyId, categoryId);
    if (!response.error) {
      yield put(addonsActions.getCompanyAddons());
    } else { throw response; }
  } catch (error) {
    console.log('deleteCategorySaga error', error);
  }
}

function* getAddonSaga(companyId, action) {
  const { addonId } = action;
  try {
    const response = yield call(getAddonService, companyId, addonId);
    if (!response.error) {
      yield put(addonsActions.succeedAddonsRetrieval(response.data));
    } else { throw response; }
  } catch (error) {
    console.log('getAddonSaga error', error);
  }
}

function* createStockTransactionSaga(companyId, action) {
  const { stockTransaction } = action;
  const { parentAddonId, addonId } = stockTransaction;
  try {
    const response = yield call(createStockTransactionService, companyId, stockTransaction);
    if (!response.error) {
      yield put(addonsActions.getAddon(parentAddonId || addonId));
    } else { throw response; }
  } catch (error) {
    console.log('createStockTransactionSaga error', error);
  }
}

function* requestAddonUpdateSaga(companyId) {
  const state = yield select();
  const { addonStore } = state.addons;
  const body = {
    addon: addonStore,
  };
  try {
    const response = yield call(requestAddonUpdateService, companyId, body);
    if (!response.error) {
      yield put(addonsActions.getCompanyAddons());
      yield put(sessionActions.updateSuccessfulSnackbar('Addon successfully updated.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'requestAddonUpdateSaga');
    yield put(sessionActions.updateErrorSnackbar(`Addon updated failed : ${error.error}`, true));
  }
}

function* requestProductUpdateSaga(companyId, action) {
  const { addonId, values } = action;
  try {
    const response = yield call(
      requestAddonUpdateService, companyId, { addon: { ...values, id: addonId } },
    );
    if (!response.error) {
      yield put(addonsActions.getAddon(addonId));
      yield put(sessionActions.updateSuccessfulSnackbar('Product successfully updated.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'requestProductUpdateSaga');
    yield put(sessionActions.updateErrorSnackbar(`Product updated failed : ${error.error}`, true));
  }
}

function* getCompanyPOSOrdersSaga(companyId, action) {
  try {
    const { reportType, selectedDates } = action;
    let startDate = null;
    let endDate = null;
    if (selectedDates) {
      startDate = moment(selectedDates.startDate).format('YYYY-MM-DD');
      endDate = moment(selectedDates.endDate).format('YYYY-MM-DD');
    }
    const response = yield call(
      getPOSReservationsService, companyId, reportType, startDate, endDate,
    );
    if (!response.error) {
      yield put(addonsActions.succeedPOSOrderRetrieval(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyPOSOrdersSaga');
  }
}

export function* getCompanyAddonsWatcher(companyId) {
  yield takeEvery(addonsActions.FACILITY_ADDONS_REQUESTED, getCompanyAddonsSaga, companyId);
}
export function* createCompanyAddonsWatcher(companyId) {
  yield takeEvery(addonsActions.CREATE_COMPANY_ADDON, createCompanyAddonSaga, companyId);
}
export function* getAddonsWatcher(companyId) {
  yield takeEvery(addonsActions.COMPANY_ADDONS_RETRIEVAL_REQUESTED, getAddonsSaga, companyId);
}
export function* createFacilityAddonsWatcher(companyId) {
  yield takeEvery(addonsActions.CREATE_FACILITY_ADDONS_REQUESTED,
    createFacilityAddonsSaga, companyId);
}
export function* deleteFacilityAddonsWatcher(companyId) {
  yield takeEvery(addonsActions.REMOVE_FACILITY_ADDONS_REQUESTED,
    deleteFacilityAddonsSaga, companyId);
}
export function* requestAddonUpdateWatcher(companyId) {
  yield takeEvery(addonsActions.UPDATE_ADDON_REQUESTED,
    requestAddonUpdateSaga, companyId);
}

export function* deleteAddonWatcher(companyId) {
  yield takeEvery(addonsActions.DELETE_ADDON_REQUESTED, deleteAddonSaga, companyId);
}

export function* createCategoryWatcher(companyId) {
  yield takeEvery(addonsActions.CREATE_CATEGORY, createCategorySaga, companyId);
}

export function* deleteCategoryWatcher(companyId) {
  yield takeEvery(addonsActions.DELETE_CATEGORY, deleteCategorySaga, companyId);
}

export function* getAddonWatcher(companyId) {
  yield takeEvery(addonsActions.GET_ADDON, getAddonSaga, companyId);
}

export function* createStockTransactionWatcher(companyId) {
  yield takeEvery(addonsActions.CREATE_STOCK_TRANSACTION, createStockTransactionSaga, companyId);
}

export function* createAddonsReservationWatcher(companyId) {
  yield takeEvery(
    addonsActions.CREATE_ADDONS_RESERVATION_REQUESTED, createAddonsReservationSaga, companyId,
  );
}

export function* editProductWatcher(companyId) {
  yield takeEvery(addonsActions.EDIT_PRODUCT, requestProductUpdateSaga, companyId);
}

export function* fetchPosOrdersWatcher(companyId) {
  yield takeEvery(addonsActions.FETCH_POS_ORDERS, getCompanyPOSOrdersSaga, companyId);
}
