import '../../styles/App.css';
import '../../styles/bookings.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialTable from 'material-table';
import JSON2CSV from 'json2csv';
import FileDownload from 'js-file-download';
import moment from 'moment';
import {
  Tooltip, Button, Paper, TextField,
} from '@material-ui/core';
import {
  Clear, Search, SaveAlt, ChevronLeft, ChevronRight, Autorenew, UnfoldMore, CallSplit,
} from '@material-ui/icons';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlertFullWidth from '../analytics/components/alerts/AlertFullWidth';
import * as helper from '../../helpers';
import { paymentStatusTextColourGenerator, parseBookingRowsForDownload } from '../../helpers';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import TableFormattedName from '../../components/TableFormattedName';
import DateRangePickerAndQuickDates from '../../components/Date/DateRangePickerAndQuickDatesWrapper';
import DownloadPdf from '../../components/DownloadPdf';
import TableRowCollapsed from './components/TableRowCollapsed';
import MultipleCancelRefundDialog from './components/MultipleCancelRefundDialog';
import RecordPaymentDialog from './components/RecordPaymentDialog';
import MultipleFacilityPicker from './components/MultipleFacilityPicker';
import * as actions from '../../reducers/bookingsReducer';
import * as reservationActions from '../../reducers/reservationsReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import SwapBookingsDialog from './components/SwapBookingsDialog';
import FacilityUpdateDialog from '../../components/FacilityUpdateDialog';
import UserTableSendMessageDialog from '../users/UserTableSendEmailDialog';
import { omitFormProperties } from '../../utils';

const statusOptions = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
];

const slotTypeOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Standard',
    value: 'RESERVATION',
  },
  {
    label: 'Subscriptions',
    value: 'SUBSCRIPTION',
  },
];

// const dateTypeOptions = [
//   {
//     label: 'Occurs',
//     value: 'OCCURS',
//   },
//   {
//     label: 'Created',
//     value: 'CREATED',
//   },
// ];

class DesktopTable extends Component {
  constructor(props) {
    super(props);
    const { searchOptions } = this.props;
    this.state = {
      selectedDates: {
        startDate: searchOptions.validFrom,
        endDate: searchOptions.validTo,
      },
      selectedStatusOption: {
        label: statusOptions[0].label, value: statusOptions[0].value,
      },
      dateError: false,
      dateRangeError: null,
      selectedSlotType: {
        label: slotTypeOptions[0].label, value: slotTypeOptions[0].value,
      },
      selectedFacilities: [],
      editBooking: null,
      isEditOpen: false,
      sendMessageRequested: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount = () => {
    const { requestFacilitiesRetrieval } = this.props;
    requestFacilitiesRetrieval();
  }

  handleSearch() {
    const {
      selectedDates,
      selectedStatusOption,
      selectedSlotType,
      selectedFacilities,
    } = this.state;
    const { searchFunction, resetSelectedRows, selectedUser } = this.props;
    const { startDate, endDate } = selectedDates;
    const options = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      status: selectedStatusOption.value,
      slotType: selectedSlotType.value,
      facilities: selectedFacilities,
    };
    resetSelectedRows();
    if (selectedUser) {
      searchFunction(options, selectedUser.id);
    } else {
      searchFunction(options);
    }
  }

  handleDateChange(options) {
    const { startDate, endDate } = options;
    if (moment(endDate).diff(moment(startDate), 'weeks') > 52) {
      this.setState({ dateRangeError: 'Date range cannot exceed 52 weeks.' });
      return;
    }
    this.setState({
      dateRangeError: null,
      selectedDates: {
        startDate: startDate !== null ? startDate.format('YYYY-MM-DD HH:mm') : startDate,
        endDate: endDate !== null ? endDate.format('YYYY-MM-DD HH:mm') : endDate,
      },
    });
  }

  handleEditBooking(booking) {
    const { toggleEditBookingDialog } = this.props;
    toggleEditBookingDialog();
    this.setState({
      editBooking: booking,
      isEditOpen: !!booking,
    });
  }

  handleEditBookingClose() {
    const { toggleEditBookingDialog } = this.props;
    toggleEditBookingDialog();
    this.setState({
      editBooking: null,
      isEditOpen: false,
    });
  }

  // Helper funtion export
  handleDefaultFilters(columnField) {
    const { storedFilters } = this.props;
    let defaultFilter = null;
    if (storedFilters[columnField]) {
      defaultFilter = storedFilters[columnField];
      return defaultFilter;
    }

    return null;
  }

  addDefaultFilter(columnArray) {
    const searchedCols = columnArray.map((col) => col.column.title);
    const { storedFilters } = this.props;
    const { updateStoredFilter } = this.props;
    const searchedPrev = Object.keys(storedFilters);
    const removedTerms = searchedPrev.filter((term) => !searchedCols.includes(term));
    removedTerms.forEach((term) => {
      updateStoredFilter(term, '');
    });
    columnArray.forEach((col) => {
      const columnField = col.column.title;
      const term = col.value;

      updateStoredFilter(columnField, term);
    });
  }

  deselectAll() {
    const {
      bookingsAndSubsciptionsSuccessful, bookings,
      requestBookingsAndSubscriptions,
    } = this.props;
    const bookingsAndSubs = [];
    bookings.forEach((reservation) => {
      const tempShape = {
        ...reservation,
        tableData: {
          checked: false,
        },
      };
      bookingsAndSubs.push(tempShape);
    });
    const {
      selectedDates,
      selectedStatusOption,
      selectedSlotType,
    } = this.state;
    const { startDate, endDate } = selectedDates;

    const options = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      status: selectedStatusOption.value,
      slotType: selectedSlotType.value,
    };
    requestBookingsAndSubscriptions(options);
    bookingsAndSubsciptionsSuccessful(bookingsAndSubs);
  }

  checkRow(reservations) {
    const { updateSelectedRows } = this.props;
    const selectedRowShape = [];
    reservations.forEach((reservation) => {
      const tempShape = {
        ...reservation,
        slot: reservation.startTime,
        id: reservation.id ? reservation.id : reservation.subscriptionId,
        userId: reservation.user.id,
        allocations: reservation.allocations.concat(reservation.amenities),
      };
      selectedRowShape.push(tempShape);
    });
    updateSelectedRows(selectedRowShape);
  }

  requestMultipleCancel() {
    const {
      selectedDates,
      selectedStatusOption,
      selectedSlotType,
    } = this.state;
    const { cancelandRefundReservations, cancelReservations } = this.props;
    let { selectedRows } = this.props;
    const { startDate, endDate } = selectedDates;
    const options = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      status: selectedStatusOption.value,
      slotType: selectedSlotType.value,
    };
    if (selectedRows[0].type !== 'SUBSCRIPTION') { selectedRows = selectedRows.map((row) => row.id); }
    cancelandRefundReservations(selectedRows, cancelReservations, false, options);
  }

  requestMultipleRecordPayment() {
    const {
      recordMultipleReservationPayment,
      recordMultipleReservationPaymentFailure,
    } = this.props;

    const { selectedRows } = this.props;
    const paidOrInvoicePendingSelected = (
      selectedRows.some((row) => row.paid === true || row.paid === null))
      || (selectedRows.some((row) => row.invoicePending === true));
    let multipleUsersSelected = false;
    const userToCheck = selectedRows[0].userId;
    // eslint-disable-next-line no-restricted-syntax
    for (const reservation of selectedRows) {
      if (reservation.userId !== userToCheck) {
        multipleUsersSelected = true;
      }
    }
    if (!multipleUsersSelected && !paidOrInvoicePendingSelected) {
      recordMultipleReservationPayment(selectedRows, false, null);
    } else if (multipleUsersSelected) {
      recordMultipleReservationPaymentFailure('You can only record payment for one user at a time.');
    } else if (paidOrInvoicePendingSelected) {
      recordMultipleReservationPaymentFailure('You can only record payment for unpaid bookings or bookings that havent been invoiced.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  lowerCaseAllWordsExceptFirstLetters(string) {
    return string.replace(/\w\S*/g, (word) => word.charAt(0) + word.slice(1).toLowerCase());
  }

  render() {
    const {
      bookings,
      stripeId,
      resetSelectedRows,
      selectedRows,
      bookingsLoading,
      facilities,
      requestFacilityBookingUpdate,
      company,
    } = this.props;

    const { currencySym } = company;

    const {
      selectedDates,
      dateError,
      selectedStatusOption,
      selectedSlotType,
      quickDateType,
      selectedFacilities,
      editBooking,
      isEditOpen,
      dateRangeError,
    } = this.state;

    if (selectedDates.endDate === null && dateError !== true) {
      this.setState({
        selectedDates: { startDate: selectedDates.startDate, endDate: selectedDates.startDate },
      });
      this.setState({ dateError: true });
    } else if (selectedDates.endDate !== null && dateError === true) {
      this.setState({ dateError: false });
    }

    const subscriptionSelected = selectedRows.some((r) => r.type === 'SUBSCRIPTION');

    const allFacilities = [];
    facilities.forEach((parentFacility) => {
      allFacilities.push(parentFacility);
      parentFacility.subFacilities.forEach((subFacility) => {
        allFacilities.push(subFacility);
      });
    });

    const handleExport = (columns, data) => {
      const mappedData = data.map((row) => ({
        'Facility Name': row.subFacilities ? row.subFacilities.map((sf) => sf.name).join(', ') : row.facilityName,
        'Start Time': row.startTime ? moment(row.startTime).tz(row.facility.timezone).format('ddd D MMM YYYY h:mm a') : '',
        'End Time': row.endTime ? moment(row.endTime).tz(row.facility.timezone).format('ddd D MMM YYYY h:mm a') : '',
        Name: row.user ? `${row.user.firstName} ${row.user.lastName}` : '',
        Email: row.user ? row.user.email : '',
        'Team Name': row.user ? row.user.teamName : '',
        Notes: row.notes ? row.notes : '',
        Amenities: row.amenityNames ? row.amenityNames.join(', ') : '',
        'Payment Status': row.paymentStatusText ? row.paymentStatusText : '',
        Status: row.status ? row.status : '',
        Price: row.total ? row.total : '',
        'Total ExTax': row.totalExTax ? row.totalExTax : '',
        Tax: row.tax ? row.tax : '',
        formData: row?.allocations && row?.allocations?.length > 0
          ? row?.allocations
            ?.map((allocation) => (allocation?.formData
              ? JSON.stringify(omitFormProperties(allocation?.formData)) : ''))
            .filter((form) => form !== '')
            .join(', ')
          : '',
      }));

      const timestamp = moment(new Date()).format('YYYY-MM-DD');
      const rowData = mappedData.map((row) => Object.values(row));
      DownloadPdf(
        Object.keys(mappedData[0]),
        rowData,
        `Pitchbooking Booking Export - (${timestamp}).pdf`,
      );
    };

    const { sendMessageRequested } = this.state;

    return (
      <div className="bookings-table-section">
        <UserTableSendMessageDialog
          open={sendMessageRequested}
          userEmails={selectedRows.map((r) => r.user.email)}
          onClose={() => {
            this.setState({ sendMessageRequested: false });
            resetSelectedRows();
          }}
          userIds={selectedRows.map((r) => r.user.id)}
        />

        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '20px',
        }}
        >
          <ConditionallyVisible condition={dateError}>
            <AlertFullWidth alertMessage="End date not set" severity="error" />
          </ConditionallyVisible>
          <ConditionallyVisible condition={dateRangeError}>
            <AlertFullWidth alertMessage={dateRangeError} severity="error" />
          </ConditionallyVisible>
        </div>
        <Paper
          style={{
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            overflowX: 'hidden',
            width: '100%',
          }}
          component={Paper}
        >
          <div style={{
            display: 'flex',
            flexWrap: 'none',
            marginTop: '20px',
            marginLeft: '20px',
            marginBottom: '20px',
            alignItems: 'center',
          }}
          >
            <DateRangePickerAndQuickDates
              quickDateType={quickDateType}
              startDate={selectedDates !== null ? moment(selectedDates.startDate)
                : selectedDates.startDate}
              endDate={selectedDates !== null ? moment(selectedDates.endDate)
                : selectedDates.endDate}
              onDatesChange={this.handleDateChange}
            />
            <Autocomplete
              value={selectedSlotType}
              onChange={(event, option) => this.setState({ selectedSlotType: { ...option } })}
              options={slotTypeOptions}
              getOptionLabel={(option) => option.label}
              style={{ width: 220, marginLeft: 20 }}
              renderInput={(params) => (
                <TextField {...params} label="Standard / Subscriptions" variant="outlined" />
              )}
              disableClearable
            />
            <Autocomplete
              value={selectedStatusOption}
              onChange={(event, option) => this.setState({ selectedStatusOption: { ...option } })}
              options={statusOptions}
              getOptionLabel={(option) => option.label}
              style={{ width: 140, marginLeft: 20 }}
              renderInput={(params) => (
                <TextField {...params} label="Status" variant="outlined" />
              )}
              disableClearable
            />
            <MultipleFacilityPicker
              facilities={allFacilities}
              selectedFacilities={selectedFacilities}
              onUpdate={(value) => this.setState({ selectedFacilities: value })}
            />
            <Button
              style={{
                width: 150, height: 56, marginLeft: 20, marginRight: 20,
              }}
              color="secondary"
              variant="contained"
              onClick={() => this.handleSearch()}
            >
              Update
            </Button>
          </div>
          <AlternativelyVisible condition={bookingsLoading}>
            <LoadingSkeletonDataTable />
            <>
              <ConditionallyVisible condition={selectedRows.length > 0}>
                <div style={{ display: 'flex', gap: '1rem', margin: '20px' }}>
                  <MultipleCancelRefundDialog
                    variant="contained"
                    buttonTitle="Cancel / Refund Bookings"
                    dialogTitle={`Cancel / Refund ${selectedRows.length} Booking${selectedRows.length > 1 ? 's' : ''}`}
                    multiple={selectedRows.length > 1}
                    selectedRows={selectedRows}
                    requestCancel={() => this.requestMultipleCancel()}
                    resetSelectedRows={() => resetSelectedRows()}
                    deselectAll={() => this.deselectAll()}
                  />
                  <RecordPaymentDialog
                    variant="contained"
                    style={{
                      marginRight: '20px',
                      marginTop: '10px',
                      width: 175,
                      backgroundColor: '#ac372f',
                      color: '#fff',
                    }}
                    buttonTitle="Record Payment"
                    dialogTitle={`Record payment for ${selectedRows.length} Booking${selectedRows.length > 1 ? 's' : ''}`}
                    requestMultipleRecordPayment={() => this.requestMultipleRecordPayment()}
                    resetSelectedRows={() => resetSelectedRows()}
                    deselectAll={() => this.deselectAll()}
                    subscriptionSelected={subscriptionSelected}
                    selectedReservations={selectedRows}
                  />
                  {/* Send message */}
                  <Button
                    id="manager-users-message-users"
                    onClick={() => this.setState({ sendMessageRequested: true })}
                    variant="contained"
                    color="primary"
                  >
                    Send Message to Customer(s)
                  </Button>
                  <ConditionallyVisible
                    condition={
                      selectedRows.length === 2
                      && selectedRows.every((sr) => sr.type !== 'SUBSCRIPTION')
                    }
                  >
                    <SwapBookingsDialog
                      isMobile={false}
                      options={{
                        startDate: moment(selectedDates.startDate).format('YYYY-MM-DD'),
                        endDate: moment(selectedDates.endDate).format('YYYY-MM-DD'),
                        status: selectedStatusOption.value,
                        slotType: selectedSlotType.value,
                      }}
                    />
                  </ConditionallyVisible>
                  <ConditionallyVisible
                    condition={
                      selectedRows.length === 1
                      && selectedRows.every((sr) => sr.type !== 'SUBSCRIPTION')
                    }
                  >
                    <Button
                      variant="contained"
                      onClick={() => this.handleEditBooking(selectedRows[0])}
                    >
                      Edit Booking
                    </Button>

                    <FacilityUpdateDialog
                      allocation={isEditOpen ? editBooking : null}
                      onClose={() => this.handleEditBookingClose()}
                      onChange={async (facility, timeslots) => {
                        requestFacilityBookingUpdate(
                          editBooking?.id,
                          timeslots.map((x) => ({
                            ...x,
                            endTime: moment(x.endTime),
                          })),
                          false,
                        );
                        this.handleEditBookingClose();
                      }}
                    />
                  </ConditionallyVisible>
                </div>
              </ConditionallyVisible>
              <MaterialTable
                className="bookings-table"
                data={bookings}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                title=""
                icons={{
                  NextPage: () => <ChevronRight />,
                  DetailPanel: () => <ChevronRight />,
                  PreviousPage: () => <ChevronLeft />,
                  Search: () => <Search />,
                  Export: () => <SaveAlt />,
                  ResetSearch: () => <Clear />,
                  SortArrow: () => <UnfoldMore style={{ fill: '#4581E2' }} />,
                  Filter: () => <Search />,
                }}
                columns={[
                  {
                    title: 'Booker',
                    customFilterAndSearch: (term, row) => {
                      const bookerName = `${row.user.firstName} ${row.user.lastName}`;
                      const bookerNameString = bookerName.toString().toLowerCase();
                      return bookerNameString.includes(term.toLowerCase());
                    },
                    render: (row) => (
                      <div style={{ display: 'flex' }}>
                        <TableFormattedName
                          user={row.user}
                          showTeam
                        />
                      </div>
                    ),
                    defaultFilter: this.handleDefaultFilters('Booker'),
                  },
                  {
                    title: 'Facility',
                    field: 'facilityName',
                    defaultFilter: this.handleDefaultFilters('Facility'),
                    render: (rowData) => (rowData.subFacilities
                      ? rowData.subFacilities.map((subFacility) => (
                        subFacility.name
                      )).join(', ')
                      : rowData.facility.name),

                  },
                  {
                    title: 'Sport',
                    customFilterAndSearch: (term, row) => {
                      const sport = `${row.sport}`;
                      const sportString = sport.toString().toLowerCase();
                      return sportString.includes(term.toLowerCase());
                    },
                    render: (rowData) => (
                      <div style={{ display: 'flex' }}>
                        {rowData.sport ? this.lowerCaseAllWordsExceptFirstLetters(rowData.sport) : 'Not Set'}
                      </div>
                    ),
                    defaultFilter: this.handleDefaultFilters('Sport'),
                  },
                  {
                    title: 'Date & Time',
                    field: 'startTime',
                    defaultSort: 'asc',
                    cellStyle: {
                      minWidth: '280px',
                    },
                    customFilterAndSearch: (term, row) => {
                      const formattedDate = moment(row.startTime).tz(row.facility.timezone).format('ddd D MMM YYYY h:mm a');
                      const formattedDateString = formattedDate.toString().toLowerCase();
                      return formattedDateString.includes(term.toLowerCase());
                    },
                    render: (rowData) => (
                      <div style={{ display: 'flex' }}>
                        {moment(rowData.startTime).tz(rowData.facility.timezone).format('ddd D MMM YYYY h:mm a')}
                      </div>
                    ),
                    defaultFilter: this.handleDefaultFilters('Date & Time'),
                  },
                  {
                    title: 'Payment Status',
                    field: 'paymentStatusText',
                    render: (row) => (
                      <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: `${paymentStatusTextColourGenerator(row.paid, row.paymentStatusText)}`,
                      }}
                      >
                        {row.paymentStatusText}
                        <ConditionallyVisible condition={row.type === 'SUBSCRIPTION' && row.accessRestriction}>
                          <Tooltip title={`Recurring: ${helper.generateValidityText(row.accessRestriction)}`} arrow>
                            <Autorenew />
                          </Tooltip>
                        </ConditionallyVisible>
                        <ConditionallyVisible condition={row.splitPayment}>
                          <Tooltip title="Payment for this booking has been split between the participants." arrow>
                            <CallSplit />
                          </Tooltip>
                        </ConditionallyVisible>
                      </div>
                    ),
                    defaultFilter: this.handleDefaultFilters('Payment Status'),
                  },
                  {
                    title: 'Invoice Number',
                    field: 'invoiceNumber',
                    render: (row) => (
                      // eslint-disable-next-line no-nested-ternary
                      row.invoiceUnpaid
                        ? invoiceNumberFormatted(row.invoiceUnpaid.invoiceNumber)
                        : row.invoicePaid
                          ? invoiceNumberFormatted(row.invoicePaid.invoiceNumber)
                          : 'Not Invoiced'
                    ),
                    defaultFilter: this.handleDefaultFilters('Invoice Number'),
                  },
                  {
                    title: 'Order Id',
                    field: 'order',
                    hidden: true,
                  },
                  {
                    title: 'Booking Status',
                    field: 'status',
                    render: (row) => (row?.status
                      ? this.lowerCaseAllWordsExceptFirstLetters(row?.status)
                      : 'Confirmed'
                    ),
                    defaultFilter: this.handleDefaultFilters('Status'),
                  },
                ]}
                detailPanel={(rowData) => (
                  <div style={{
                    backgroundColor: '#efefef',
                  }}
                  >
                    <TableRowCollapsed
                      reservation={rowData}
                      stripeId={stripeId}
                      currencySym={currencySym}
                    />
                  </div>
                )}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  exportButton: {
                    csv: true,
                    pdf: true,
                  },
                  tableLayout: 'auto',
                  exportFileName: 'Pitchbooking bookings',
                  exportCsv: (columns, data) => {
                    const { colHeaders, rows } = parseBookingRowsForDownload(columns, data);
                    const csv = JSON2CSV({ data: rows, fields: colHeaders, excelStrings: false });
                    const timestamp = moment(new Date()).format('YYYY-MM-DD');
                    const filename = `Pitchbooking Booking Export (${timestamp}).csv`;
                    FileDownload(csv, filename);
                  },
                  exportPdf: (columns, data) => handleExport(columns, data),
                  pageSize: 50,
                  pageSizeOptions: [10, 25, 50, 100],
                  showFirstLastPageButtons: false,
                  showSelectAllCheckbox: false,
                  selection: true,
                  headerStyle: {
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                  },
                }}
                components={{
                  Action: () => (
                    <>
                      {/* This empty div is needed for the render */}
                    </>
                  ),
                }}
                onSelectionChange={(rows) => this.checkRow(rows)}
                onFilterChange={(e) => this.addDefaultFilter(e)}
                actions={[
                  {
                    tooltip: 'Bookings actions',
                  },
                ]}
                localization={{
                  toolbar: {
                    exportTitle: 'Download',
                  },
                }}
              />
            </>
          </AlternativelyVisible>
        </Paper>
      </div>
    );
  }
}

DesktopTable.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  storedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchOptions: PropTypes.shape().isRequired,
  stripeId: PropTypes.string.isRequired,
  selectedRows: PropTypes.shape().isRequired,
  facilities: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  toggleEditBookingDialog: PropTypes.func.isRequired,
  updateSelectedRows: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape().isRequired,
  cancelReservations: PropTypes.shape().isRequired,
  cancelandRefundReservations: PropTypes.func.isRequired,
  updateSearchOptions: PropTypes.func.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  updateStoredFilter: PropTypes.func.isRequired,
  searchFunction: PropTypes.func.isRequired,
  recordMultipleReservationPayment: PropTypes.func.isRequired,
  requestBookingsAndSubscriptions: PropTypes.func.isRequired,
  bookingsAndSubsciptionsSuccessful: PropTypes.func.isRequired,
  recordMultipleReservationPaymentFailure: PropTypes.func.isRequired,
  requestFacilityBookingUpdate: PropTypes.func.isRequired,
  company: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  company: state.companies.companyInfo,
  selectedRows: state.bookings.selectedRows,
  searchOptions: state.bookings.searchOptions,
  storedFilters: state.bookings.storedFilters,
  bookingsLoading: state.bookings.bookingsLoading,
  cancelReservations: state.reservation.cancelReservations,
  facilities: state.facilities.companyFacilities,
  stripeId: state.companies.companyInfo.stripeId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  bookingsAndSubsciptionsSuccessful: (bookings) => actions.bookingsAndSubsciptionsSuccessful(
    bookings,
  ),
  updateStoredFilter: (columnField, term) => actions.updateStoredFilter(columnField, term),
  updateSearchOptions: (value) => actions.updateSearchOptions(value),
  toggleEditBookingDialog: () => actions.toggleEditBookingDialog(),
  updateSelectedRows: (rows) => actions.updateSelectedBookingsAndSubscriptions(rows),
  requestBookingsAndSubscriptions: (options) => actions.requestBookingsAndSubscriptions(options),
  resetSelectedRows: () => actions.resetSelectedRows(),
  cancelandRefundReservations: (
    reservations, refund, sendEmail, options,
  ) => reservationActions.cancelReservations(
    reservations, refund, sendEmail, options,
  ),
  recordMultipleReservationPayment: (
    reservations, paymentType, options,
  ) => reservationActions.recordMultipleReservationPayment(reservations, paymentType, options),
  recordMultipleReservationPaymentFailure: (
    error,
  ) => reservationActions.recordMultipleReservationPaymentFailure(error),
  requestFacilitiesRetrieval: () => facilitiesActions.requestFacilitiesRetrieval(),
  requestFacilityBookingUpdate: (
    bookingId,
    timeslots,
    resetRows,
  ) => actions.updateFacilityBooking(bookingId, timeslots, resetRows),

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DesktopTable);
