import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Moment from 'moment';
import { DateRangePicker } from 'react-dates';
import '../../styles/App.css';
import * as actions from '../../reducers/bookingsReducer';
import MobileBookingResult from './components/MobileBookingResult';

const MobileTable = ({
  getBookings,
  getSubscriptions,
  bookings,
}) => {
  const [fromDate, setFromDate] = useState(Moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(Moment().add(7, 'days').format('YYYY-MM-DD'));
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const dates = {
      fromDate,
      toDate,
    };
    getBookings(dates);
    getSubscriptions(dates);
  }, []);

  const handleDateChange = (startDate, endDate) => {
    const start = Moment(startDate).format('YYYY-MM-DD');
    const end = Moment(endDate).format('YYYY-MM-DD');

    setFromDate(start);
    setToDate(end);

    if (startDate && endDate) {
      const dates = {
        fromDate: start,
        toDate: end,
      };
      getBookings(dates);
      getSubscriptions(dates);
    }
  };

  const formattedBookings = bookings.bookings;
  const formattedSubscriptions = bookings.subscriptionBookings;
  const mergedBookings = formattedBookings.concat(formattedSubscriptions);
  const sortedBookings = mergedBookings.sort((a, b) => {
    const keyA = Moment(b.startTime).unix();
    const keyB = Moment(a.startTime).unix();
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <DateRangePicker
          startDate={Moment(fromDate)}
          startDateId="startDate"
          endDate={Moment(toDate)}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => {
            handleDateChange(startDate, endDate);
          }}
          focusedInput={focused}
          onFocusChange={(focusedInput) => setFocused(focusedInput)}
          displayFormat="ddd, DD-MMM-YYYY"
          isOutsideRange={() => false}
          numberOfMonths={1}
          showDefaultInputIcon
          withFullScreenPortal
          id="dateRangePicker"
          noBorder
        />
      </div>

      {sortedBookings.length > 0 ? (
        sortedBookings.map((mobileListing) => (
          <MobileBookingResult
            key={mobileListing.id}
            booking={mobileListing}
          />
        ))
      ) : (
        <div className="mobileBookingContainer">
          <Typography variant="h5">No Results Found</Typography>
          <Typography variant="subtitle1">Try a different search date.</Typography>
        </div>
      )}
    </div>
  );
};

MobileTable.propTypes = {
  getBookings: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  bookings: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const { bookings, users, companies } = state;
  const { currencySym } = state.companies.companyInfo;

  return {
    bookings,
    users,
    companies,
    currencySym,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getBookings: (dates) => dispatch(actions.getBookings(dates)),
  getSubscriptions: (dates) => dispatch(actions.getSubscriptionBookings(dates)),
  getUnpaidBookings: () => dispatch(actions.getUnpaidBookings()),
  getCancelledBookings: () => dispatch(actions.getCancelledBookings()),
  getRequestedBookings: () => dispatch(actions.getRequestedBookings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTable);
