import React from 'react';
import { Route } from 'react-router-dom';
import Bookings from '../containers/bookings';
import Payments from '../containers/payments';
import Facilities from '../containers/facilities';
import AccessRestrictions from '../containers/accessRestrictions';
import Pricing from '../containers/pricing';
import EditFacility from '../containers/facilities/components/EditFacility';
import Subscriptions from '../containers/subscriptions/subscriptions';
import SubscriptionView from '../containers/subscriptions/SubscriptionView';
import BookingView from '../containers/bookings/components/bookingsView';
import Users from '../containers/users';
import Invoices from '../containers/invoices';
import Memberships from '../containers/memberships';
import MembershipMedia from '../containers/memberships/MembershipMedia';
import MembershipView from '../containers/memberships/membershipView';
import Calendar from '../containers/calendar';
import BookingModifiers from '../containers/bookingModifiers';
import Analytics from '../containers/analytics';
import EventAnalytics from '../containers/eventAnalytics';
import TeamMembers from '../containers/teamMembers';
import Billing from '../containers/billing';
import BillingProcessing from '../containers/billing/billingProcessing';
import CreateBilling from '../containers/billing/createBilling';
import Payouts from '../containers/payouts';
import PayoutView from '../containers/payouts/payoutView';
import UserView from '../containers/users/userOverview/userView';
import CompanySettings from '../containers/companySettings';
import Calculators from '../containers/calculators';
import Dashboard from '../containers/dashboard';
import Faq from '../containers/help';
import Schedules from '../containers/schedules';
import Events from '../containers/events';
import EventMedia from '../containers/events/eventsView/EventMedia';
import EventPayLater from '../containers/events/eventsView/EventPayLater';
import Leagues from '../containers/leagues';
import LeagueMedia from '../containers/leagues/leagueView/LeagueMedia';
import LeagueView from '../containers/leagues/leagueView';
import Profile from '../containers/profile';
import Enquiries from '../containers/enquiries';
import Enquiriy from '../containers/enquiries/EnquiryView';
import EventsView from '../containers/events/eventsView';
import Addons from '../containers/addons';
import Utils from '../containers/utils';
import Associates from '../containers/associates';
import Referral from '../containers/referral';
import Payment from '../containers/payments/payment';
import Forms from '../containers/forms';
import { Unlock } from '../containers/unlock';
import { MembershipUserView } from '../containers/memberships/MembershipUserView';
import { Map } from '../containers/map';
import Welcome from '../containers/welcome';
import WelcomeConfirmed from '../containers/welcome/welcome-confirmed';
import Product from '../containers/product';
import { Verify } from '../containers/verify';
import AccountManager from '../containers/accountManager';
import { ContactUs } from '../containers/contactUs';
import NextSteps from '../containers/welcome/nextSteps';
import Companies from '../containers/accountManager/companies';
import AddonView from '../containers/addons/AddonView';
import POS from '../containers/pos';
import PosPayment from '../containers/pos/PosPayment';
import AddonMedia from '../containers/addons/AddonMedia';
import OrderHistory from '../containers/pos/OrderHistory';
import StripeRequired from '../containers/companySettings/StripeRequired';
import { Library } from '../containers/Library';
import { useCompany } from '../hooks';

const PageRoutes = () => {
  const { products } = useCompany();

  return (
    <>
      <Route exact path="/" component={products.facilities === 'ENABLED' ? Calendar : Analytics} />
      <Route exact path="/index.html" component={Bookings} />
      <Route exact path="/bookings" component={Bookings} />
      <Route exact path="/bookings/:reservationId" component={BookingView} />
      <Route exact path="/subscriptions" component={Subscriptions} />
      <Route exact path="/invoices" component={Invoices} />
      <Route exact path="/products" component={Addons} />
      <Route exact path="/products/:id" component={AddonView} />
      <Route exact path="/products/:id/media" component={AddonMedia} />
      <Route exact path="/point-of-sale" component={POS} />
      <Route exact path="/point-of-sale-order-history" component={OrderHistory} />
      <Route exact path="/point-of-sale/:reservationId" component={PosPayment} />
      <Route exact path="/forms" component={Forms} />
      <Route exact path="/company-settings" component={CompanySettings} />
      <Route exact path="/subscriptions/:subscriptionId" component={SubscriptionView} />
      <Route exact path="/booking-modifiers" component={BookingModifiers} />
      <Route exact path="/bookings/requests" component={Bookings} />
      <Route exact path="/facilities" component={Facilities} />
      <Route exact path="/calendar" component={Calendar} />
      <Route exact path="/calculator" component={Calculators} />
      <Route exact path="/companies" component={Companies} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/analytics" component={Analytics} />
      <Route exact path="/event-analytics" component={EventAnalytics} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/users/:id" component={UserView} />
      <Route exact path="/memberships" component={Memberships} />
      <Route exact path="/memberships/:membershipId" component={MembershipView} />
      <Route exact path="/memberships/:membershipId/user/:userId" component={MembershipUserView} />
      <Route exact path="/memberships/:membershipId/media" component={MembershipMedia} />
      <Route exact path="/closures" component={AccessRestrictions} />
      <Route exact path="/priorityaccess" component={AccessRestrictions} />
      <Route exact path="/pricing" component={Pricing} />
      <Route exact path="/payments" component={Payments} />
      <Route exact path="/payments/:orderId" component={Payment} />
      <Route exact path="/facilities/edit/:facilityid" component={EditFacility} />
      <Route exact path="/team-members" component={TeamMembers} />
      <Route exact path="/billing" component={CreateBilling} />
      <Route exact path="/agreement/:billingId" component={Billing} />
      <Route exact path="/agreement/:billingId/processing" component={BillingProcessing} />
      <Route exact path="/billing/:billingId" component={Billing} />
      <Route exact path="/billing/:billingId/processing" component={BillingProcessing} />
      <Route exact path="/welcome" component={Welcome} />
      <Route exact path="/welcome-confirmed" component={WelcomeConfirmed} />
      <Route exact path="/payouts" component={Payouts} />
      <Route exact path="/payouts/:payoutId" component={PayoutView} />
      <Route exact path="/help" component={Faq} />
      <Route exact path="/schedules" component={Schedules} />
      <Route exact path="/leagues" component={Leagues} />
      <Route exact path="/leagues/:id/media" component={LeagueMedia} />
      <Route exact path="/leagues/:id" component={LeagueView} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/events/:id" component={EventsView} />
      <Route exact path="/events/:id/media" component={EventMedia} />
      <Route exact path="/events/:id/pay-later" component={EventPayLater} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/messages" component={Enquiries} />
      <Route exact path="/messages/:id" component={Enquiriy} />
      <Route exact path="/utils" component={Utils} />
      <Route exact path="/product/:type" component={Product} />
      <Route exact path="/utils/xero-auth" component={Utils} />
      <Route exact path="/associates" component={Associates} />
      <Route exact path="/referrals" component={Referral} />
      <Route exact path="/unlock" component={Unlock} />
      <Route exact path="/map" component={Map} />
      <Route exact path="/verify" component={Verify} />
      <Route exact path="/account-manager" component={AccountManager} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/next-steps" component={NextSteps} />
      <Route exact path="/stripe-setup" component={StripeRequired} />
      <Route exact path="/library" component={Library} />
    </>
  );
};

export default PageRoutes;
