/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import moment from 'moment-timezone';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import UpdateIcon from '@material-ui/icons/Update';
import PropTypes from 'prop-types';
import '../../styles/payments.css';
import { priceTaxCalculator } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { Button } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import SubscriptionStripeOrdersTable from './components/SubscriptionStripeOrdersTable';
import Back from '../../components/Back';
import { generateValidityText } from '../../helpers';
import ToolTip from '../../components/Tooltip';
import { MaterialTable } from '../../components/MaterialTable';
import { getSubscriptionService } from '../../services/subscriptionsServices';
import {
  SubscriptionEditPriceDialog,
  SubscriptionAddExclusionDialog,
  SubscriptionDeleteExclusionDialog,
  SubscriptionAddNoteDialog,
  SubscriptionDeleteNoteDialog,
} from './components/Dialog';
import { useOrigin, useCompany } from '../../hooks';

// TODO: These components need deleted
// import SubscriptionExclusionsDialog from './SubscriptionExclusionsDialog';
// import SubscriptionNotesDialog from './SubscriptionNotesDialog';
// import SubscriptionExclusionsDeleteDialog from './SubscriptionExclusionsDeleteDialog';

const SubscriptionView = (props) => {
  const companyInfo = useCompany();
  const { subscriptionId } = props.match.params;
  const [isEditPriceDialogOpen, setIsEditPriceDialogOpen] = useState(false);
  const [isAddExclusionDialogOpen, setIsAddExclusionDialogOpen] = useState(false);
  const [isAddNoteDialogOpen, setIsAddNoteDialogOpen] = useState(false);
  const [deleteExclusionDialog, setDeleteExclusionDialog] = useState({
    isOpen: false,
    exclusionId: null,
  });
  const [deleteNoteDialog, setDeleteNoteDialog] = useState({
    isOpen: false,
    noteId: null,
  });

  const { data: subscription, isLoading, isError } = useQuery({
    queryKey: ['subscription', subscriptionId],
    queryFn: async () => {
      const { data, status } = await getSubscriptionService(companyInfo.id, subscriptionId);
      if (status !== 200) {
        throw new Error('Failed to fetch subscription');
      }

      return data;
    },
    enabled: !!companyInfo.id,
  });

  const exclusions = React.useMemo(() => {
    if (!subscription || !subscription?.accessRestriction?.exclusions) {
      return [];
    }

    return subscription.accessRestriction.exclusions.sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    );
  }, [subscription]);

  if (isLoading) {
    return (<LoadingSkeletonDataTable />);
  }

  if (isError || !subscription) {
    return (
      <div>Error</div>
    );
  }

  const {
    accessRestriction, amount, taxExempt, type, status, stripePaymentType,
  } = subscription;

  const stripePaymentTypeText = stripePaymentType ? stripePaymentType.toLowerCase() : '';
  const { facilities } = accessRestriction;
  let facility = null;
  let facilityName;
  let timezone = 'Europe/London';
  if (facilities && facilities.length > 0) {
    [facility] = facilities;
    facilityName = facility.name;
    timezone = facility.timezone;
  }
  const { stripeId, taxRate, currencySym } = companyInfo;
  const { totalExTax, tax } = priceTaxCalculator(amount / 100, taxExempt, taxRate);

  let activeStatus = '';

  if (type === 'PAYP') {
    activeStatus = 'Invoice / Pay on Arrival';
  } else if (type === 'STRIPE_SUBSCRIPTION' && status === 'ACTIVE') {
    activeStatus = `${stripePaymentTypeText} Payment Confirmed`;
  } else if (type === 'STRIPE_SUBSCRIPTION' && status === 'INACTIVE') {
    activeStatus = 'Awaiting Payment Confirmation';
  } else if (type === 'STRIPE_SUBSCRIPTION' && status === 'PROCESSING') {
    activeStatus = 'Processing Payment Setup';
  }

  const { managerUrl } = useOrigin();

  return (
    <div style={{ padding: 20 }}>

      <section id="subscription-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Back />
          {subscription.stripeSubscriptionId && (
            <a
              href={`https://dashboard.stripe.com/${stripeId}/subscriptions/${subscription.stripeSubscriptionId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ height: '100%' }}
              >
                View in stripe
              </Button>
            </a>
          )}
          {companyInfo?.products?.updateSubscriptionPrice === 'ENABLED' && (subscription.stripeSubscriptionId || subscription.type === 'PAYP') && (!subscription.nextSubscriptionId) && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsEditPriceDialogOpen(true)}
            >
              Edit Subscription Price
            </Button>
          )}

        </div>
        <ToolTip contentfulName="subscriptionViewToolTip" />
      </section>

      <section id="subscription-card" className="subscription-view-card">
        <h2>Subscription Details</h2>
        {subscription.nextSubscriptionId && (
          <>
            <div className="break" />
            <h3>
              <UpdateIcon
                color="error"
                style={{
                  alignSelf: 'center', verticalAlign: 'middle', marginRight: '10px',
                }}
              />
              {`The price of this subscription will change on ${moment(subscription.accessRestriction.validTo).add(1, 'week').format('Do MMM YYYY')}, `}
              <Link to={`/subscriptions/${subscription.nextSubscriptionId}`}>see updated subscription here</Link>
            </h3>
          </>
        )}
        <div className="break" />
        <h3>
          <PeopleIcon style={{
            alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
          }}
          />
          {subscription.subscriptionName}
          {' ('}
          <a href={`${managerUrl}/users/${subscription.user?.id}`}>
            {subscription.user?.firstName}
            {' '}
            {subscription.user?.lastName}
          </a>
          )
        </h3>
        <div className="break" />
        <EmailIcon style={{
          alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
        }}
        />
        {'  '}
        <h3>{subscription.user.email}</h3>
        <div className="break" />
        <ConditionallyVisible condition={subscription.user.phone}>
          <PhoneIcon style={{
            alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
          }}
          />
          <h3>
            +
            {subscription.user.dialCode}
          </h3>
          {'  '}
          <h3>{subscription.user.phone}</h3>
        </ConditionallyVisible>
        <div className="break" />
        <ReceiptIcon style={{
          alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
        }}
        />
        {'  '}
        <h3 style={{ textTransform: 'capitalize' }}>{activeStatus}</h3>
        <div className="break" />
        <h3>
          <InfoIcon style={{
            alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
          }}
          />
          {facilityName}
        </h3>
        <div className="break" />
        <h3>
          <ScheduleIcon style={{
            alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
          }}
          />
          {`${moment.weekdays(subscription.accessRestriction.weekday)} - ${moment(subscription.accessRestriction.startTime, 'HH:mm:ss').format('h:mm A')} - ${moment(subscription.accessRestriction.endTime, 'HH:mm:ss').add(1, 'seconds').format('h:mm A')}`}
        </h3>
        <div className="break" />
        <h3>
          <DateRangeIcon style={{
            alignSelf: 'center',
            verticalAlign:
              'middle',
            color: '#4781e2',
            marginRight: '10px',
          }}
          />
          {generateValidityText(accessRestriction, timezone)}
        </h3>
        <div className="break" />
        <h3>
          <LocalOfferIcon style={{
            alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', marginRight: '10px',
          }}
          />
          {`${currencySym}${parseFloat(amount / 100).toFixed(2)} (${currencySym}${parseFloat(totalExTax).toFixed(2)} + ${currencySym}${parseFloat(tax).toFixed(2)} tax)`}
        </h3>
        <div className="break" />
        <h3>
          <DateRangeIcon style={{
            alignSelf: 'center',
            verticalAlign: 'middle',
            color: '#4781e2',
            marginRight: '10px',
          }}
          />
          Created At:
          {' '}
          {moment(subscription.createdAt).tz(timezone).format('DD-MMM-YYYY, HH:mm A')}
        </h3>
      </section>

      <section
        id="subscription-exclusions"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsAddExclusionDialogOpen(true);
            }}
          >
            Add Exclusion
          </Button>
        </div>

        <MaterialTable
          data={exclusions}
          title="Exclusions"
          ToolTip={() => <ToolTip contentfulName="exclusionsToolTip" />}
          columns={[
            {
              title: 'Excluded Date',
              field: 'date',
              render: (row) => (<div>{moment(row.date).format('DD/MM/YYYY')}</div>),
            },
            {
              title: 'Created At',
              field: 'createdAt',
              render: (row) => (<div>{moment(row.createdAt).format('DD/MM/YYYY')}</div>),
            },
            {
              title: 'Actions',
              field: 'actions',
              render: (exclusion) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setDeleteExclusionDialog({
                      isOpen: true,
                      exclusionId: exclusion.id,
                    });
                  }}
                >
                  Delete Exclusion
                </Button>
              ),
            },
          ]}
          options={{
            exportFileName: 'Pitchbooking Exclusions',
          }}
        />

        <SubscriptionAddExclusionDialog
          isOpen={isAddExclusionDialogOpen}
          onClose={() => setIsAddExclusionDialogOpen(false)}
          subscription={subscription}
        />

        <SubscriptionDeleteExclusionDialog
          isOpen={deleteExclusionDialog.isOpen}
          onClose={() => setDeleteExclusionDialog({ isOpen: false, exclusionId: null })}
          subscription={subscription}
          exclusionId={deleteExclusionDialog.exclusionId}
        />
      </section>

      <section
        id="subscription-notes"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >

        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsAddNoteDialogOpen(true);
            }}
          >
            Add Note
          </Button>
        </div>

        <MaterialTable
          title="Notes"
          data={subscription.notes}
          columns={[
            {
              title: 'Subscription Date',
              field: 'date',
              render: (row) => (<div>{moment(row.date).format('DD/MM/YYYY')}</div>),
            },
            {
              title: 'Created At',
              field: 'createdAt',
              render: (row) => (<div>{moment(row.createdAt).format('DD/MM/YYYY')}</div>),
            },
            {
              title: 'Note',
              field: 'value',
            },
            {
              title: 'Actions',
              field: 'actions',
              render: (row) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setDeleteNoteDialog({
                      isOpen: true,
                      noteId: row.id,
                    });
                  }}
                >
                  Delete Note
                </Button>
              ),
            },
          ]}
          options={{
            exportFileName: 'Pitchbooking Exclusions',
          }}
          ToolTip={() => <ToolTip contentfulName="subscriptionNotesToolTip" />}
        />

        <SubscriptionAddNoteDialog
          isOpen={isAddNoteDialogOpen}
          onClose={() => setIsAddNoteDialogOpen(false)}
          subscription={subscription}
        />

        <SubscriptionDeleteNoteDialog
          isOpen={deleteNoteDialog.isOpen}
          onClose={() => setDeleteNoteDialog({ isOpen: false, noteId: null })}
          subscription={subscription}
          noteId={deleteNoteDialog.noteId}
        />
      </section>

      <SubscriptionStripeOrdersTable
        subscription={subscription}
        stripeCompanyId={stripeId}
      />

      <SubscriptionEditPriceDialog
        isOpen={isEditPriceDialogOpen}
        subscription={subscription}
        onClose={() => setIsEditPriceDialogOpen(false)}
      />
    </div>
  );
};

SubscriptionView.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default SubscriptionView;
