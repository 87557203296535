import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { priceTaxCalculator } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import {
  TextField,
  Typography,
  Checkbox,
  InputAdornment,
  Card,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import ToggleButtons from '../../../../components/ToggleButtons';
import * as actions from '../../../../reducers/subscriptionsReducer';
import MenuHeading from '../../../../components/MenuHeading';
import { getCompanyUserByEmail, getCompanyUserPaymentMethods } from '../../../../services/usersServices';
import { useCompany } from '../../../../hooks';
import { pickProperties } from '../../../../utils';

const BookingStepperPayment = () => {
  const dispatch = useDispatch();
  const updateSubscriptionStore = (keyWithValue) => dispatch(
    actions.updateSubscriptionStore(keyWithValue),
  );

  const { id: companyId, stripeId, products } = useCompany();
  const subUserData = useSelector((state) => state.subscriptions.userDetailsForSubscription);
  const subscription = useSelector((state) => state.subscriptions.subscription);
  const conflicts = useSelector((state) => state.subscriptions.conflicts);
  const applyExclusions = useSelector((state) => state.subscriptions.applyExclusions);
  const isCardNotPresentEnabled = products?.cardNotPresent === 'ENABLED';

  const { data: user } = useQuery({
    queryKey: ['user', 'email', subUserData.email],
    queryFn: async () => {
      if (subUserData?.id) {
        return subUserData;
      }

      const { data } = await getCompanyUserByEmail(companyId, subUserData.email);
      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: isCardNotPresentEnabled,
  });

  const isPaymentMethodsEnabled = isCardNotPresentEnabled && user?.id;

  const {
    data: paymentMethods,
    isLoading: isPaymentMethodsLoading,
    isError: isPaymentMethodsError,
  } = useQuery(
    {
      queryKey: ['paymentMethods', user?.id],
      queryFn: async () => {
        if (!user?.id) return [];
        const { data } = await getCompanyUserPaymentMethods(companyId, user.id);
        return data.filter((x) => x.isManagerPaymentsEnabled);
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
      enabled: isPaymentMethodsEnabled && !!user?.id,
    },
  );

  useEffect(() => {
    updateSubscriptionStore({ subscriptionName: `${subUserData.firstName} ${subUserData.lastName} ${subUserData.teamName}` });
  }, []);

  const taxRate = useSelector((state) => state.companies.companyInfo.taxRate) ?? 0.2;
  const currencySym = useSelector((state) => state.companies.companyInfo.currencySym) ?? '£';
  const facility = useSelector((state) => state.facilities.selectedFacility);

  const { subscriptionName, amount, taxExempt } = subscription;
  const { totalExTax, tax } = priceTaxCalculator(amount, taxExempt, taxRate);

  let stripeDisabled = {};
  if (parseFloat(amount) === 0) {
    stripeDisabled = { disabled: true };
  }

  useEffect(() => {
    updateSubscriptionStore({ taxExempt: !!facility.taxExempt });
  }, [facility.taxExempt]);

  const subscriptionTypeButtonsData = [{
    buttonTitle: 'Automatic',
    buttonValue: 'STRIPE_SUBSCRIPTION',
    ...stripeDisabled,
  },
  {
    buttonTitle: 'Invoice / Pay on Arrival',
    buttonValue: 'PAYP',
  },
  ...(isCardNotPresentEnabled && user?.id ? [{
    buttonTitle: 'Card Not Present',
    buttonValue: 'CARD_NOT_PRESENT',
  }] : []),
  ];

  const generateConflictsMetadata = (applyExclusions) => {
    if (!applyExclusions) {
      return {};
    }

    return {
      conflictingAllocations: JSON.stringify([...new Set(conflicts.conflictingAllocations?.map(
        (x) => x.id,
      ))]),
      conflictingAccessRestrictions: JSON.stringify([
        ...new Set(conflicts.conflictingAccessRestrictions?.map(
          (x) => x.id,
        ))]),
      conflictingFacilitySubscriptions: JSON.stringify([...new Set(
        conflicts.conflictingFacilitySubscriptions?.map(
          (x) => x.id,
        ),
      )]),
    };
  };

  const subscriptionNameInput = (
    <div style={{ marginBottom: '2rem' }}>
      <MenuHeading title="Subscription Title:" />
      <Typography variant="caption" style={{ display: 'block', marginBottom: '1rem' }}>
        Enter a descriptive name for this subscription.
      </Typography>
      <TextField
        id="subscriptionName"
        label="E.g. Senior Team Training"
        placeholder="Enter subscription title"
        type="text"
        variant="outlined"
        fullWidth
        value={subscriptionName}
        onChange={(event) => updateSubscriptionStore({ subscriptionName: event.target.value })}
      />
    </div>
  );

  const subscriptionPriceInput = (
    <div style={{ marginBottom: '2rem' }}>
      <MenuHeading title="Subscription Price:" />
      <Typography variant="caption" style={{ display: 'block', marginBottom: '1rem' }}>
        The following price is based on your current pricing rules. You can edit the price below.
      </Typography>
      <TextField
        id="amount"
        type="number"
        fullWidth
        value={amount}
        variant="outlined"
        onChange={(event) => updateSubscriptionStore({ amount: event.target.value })}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currencySym}</InputAdornment>,
        }}
      />
      <MenuHeading title="Subscription Tax:" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">
          Include tax for this subscription?
        </Typography>
        <Checkbox
          checked={!taxExempt}
          onChange={() => updateSubscriptionStore({ taxExempt: !taxExempt })}
          name="subscriptionTaxCharged"
          color="secondary"
        />
      </div>
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        {`Total Price: ${currencySym}${parseFloat(totalExTax).toFixed(2)} + ${currencySym}${parseFloat(tax).toFixed(2)} tax`}
      </Typography>
    </div>
  );

  const subscriptionPaymentInput = (
    <div>
      <MenuHeading title="Payment:" />
      <ToggleButtons
        buttonsData={subscriptionTypeButtonsData}
        changeOption={(option) => updateSubscriptionStore(
          { type: option },
        )}
        value={subscription.type}
      />
      <ConditionallyVisible condition={subscription.type === 'STRIPE_SUBSCRIPTION'}>
        {isPaymentMethodsEnabled && (
          <>
            {isPaymentMethodsLoading && <div>Loading payment methods...</div>}
            {isPaymentMethodsError && <div>Error loading payment methods.</div>}
            {paymentMethods && paymentMethods.length > 0 && (
            <>
              <Typography variant="body2" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                You can now select a saved payment method to use for this subscription.
              </Typography>
              <div style={{
                marginTop: '1rem', display: 'flex', flexWrap: 'wrap', gap: '1rem',
              }}
              >
                {paymentMethods.map((method) => (
                  <Card
                    key={method.id}
                    variant="outlined"
                    style={{
                      flex: '1 0 calc(50% - 0.5rem)',
                      minWidth: '200px',
                      maxWidth: '300px',
                      marginBottom: '0.5rem',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: subscription.paymentMethod === method.id ? '2px solid #4a90e2' : '1px solid rgba(0, 0, 0, 0.12)',
                      backgroundColor: subscription.paymentMethod === method.id ? '#f0f8ff' : 'transparent',
                      transition: 'background-color 0.3s ease-in-out',
                    }}
                    onClick={() => {
                      updateSubscriptionStore({
                        paymentMethod: subscription.paymentMethod === method.id ? null : method.id,
                      });
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      {method.type === 'card' && (
                      <>
                        <CreditCardIcon />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body1">
                            {`${method.additional?.brand?.charAt(0).toUpperCase() + method.additional?.brand?.slice(1)} ${method.last4}`}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {`Expires ${method.additional?.expiry}`}
                          </Typography>
                        </div>
                      </>
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            </>
            )}
          </>
        )}
        <MenuHeading title="Payment information" />
        <Typography variant="body2">
          A subscription is active when the customer makes their first payment.
          The customer will be charged via Stripe every week on the day of their slot.
          You can view the status of the subscription under the
          status column within the subscriptions menu.
        </Typography>
      </ConditionallyVisible>
      <ConditionallyVisible condition={subscription.type === 'CARD_NOT_PRESENT'}>
        <div style={{ marginBottom: '1.5rem' }}>
          <MenuHeading title="Payment information" />
          <Typography variant="body2">
            Capture the customers payment method details now which will create and set
            up their subscription immediately.
          </Typography>
        </div>

        <StripeCheckout
          mode="SETUP_INTENT"
          transactionType="MANAGER_SUBSCRIPTION"
          buttonText="Subscribe"
          companyStripeId={stripeId}
          redirectUrl={`${window.location.origin}/calendar`}
          metadata={{
            ...pickProperties(
              subscription,
              'amount',
              'startTime',
              'endTime',
              'endTimeFormatted',
              'startTimeFormatted',
              'numOfWeeks',
              'pitchSplit',
              'recurrence',
              'subscriptionName',
              'taxExempt',
              'paymentMethod',
              'validFrom',
              'validTo',
              'validToType',
              'weekday',
              'facilityId',
              'facilityIds',
            ),
            applyExclusions,
            ...generateConflictsMetadata(applyExclusions),
            isCardNotPresent: true,
            userId: user?.id,
            type: 'AUTOMATIC',
            companyId,
          }}
        />
      </ConditionallyVisible>
    </div>
  );

  return (
    <div>
      {subscriptionNameInput}
      {subscriptionPriceInput}
      {subscriptionPaymentInput}
    </div>
  );
};

export default BookingStepperPayment;
