import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { Typography } from '@material-ui/core';
import { getOrderService } from '../../services/ordersServices';
import PayoutOrdersTable from '../payouts/PayoutTable';
import { valueToTagMap } from './OrderTable';

const Payment = ({
  match, companyId, timezone, stripeId,
}) => {
  const [order, setOrder] = useState(null);
  const { orderId } = match.params;
  const currency = useSelector((state) => state.companies?.companyInfo?.currency);
  const currencySym = getCurrencySymbol(currency);

  useEffect(() => {
    if (companyId && orderId) {
      getOrderService(companyId, orderId).then((res) => setOrder(res.data));
    }
  }, [companyId]);

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '0.5rem', backgroundColor: '#fff', padding: 20,
    }}
    >
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {`Payment for Order: ${orderId}`}
      </Typography>

      {order && order !== null && order.user && (
        <>
          {/* User */}
          <Typography variant="subtitle1">
            {'Payment made by: '}
            {order.user && !order.isAnonymousPayment
              ? (
                <a href={`/users/${order.user.id}`}>
                  {`${order.user.firstName} ${order.user.lastName}`}
                </a>
              )
              : `${order.note} (No Account)`}

          </Typography>

          {/* Paid at */}
          <Typography variant="subtitle1">
            {`Payment made at: ${moment(order.createdAt).tz(timezone).format('D MMM YYYY, LT')}`}
          </Typography>

          {/* Amount */}
          <Typography variant="subtitle1">
            {`Payment amount: ${currencySym}${(order.total / 100).toFixed(2)} (${currencySym}${order.totalExTax.toFixed(2)} + ${currencySym}${(order.tax).toFixed(2)} tax)`}
          </Typography>

          {/* Payment method */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Typography variant="subtitle1">
              Payment method:
            </Typography>
            {valueToTagMap(order.paymentMethod, stripeId, order.chargeId)}

            {order?.paymentMethod === 'STRIPE' && (
            <Typography variant="subtitle1">
              <a href={`https://dashboard.stripe.com/${stripeId}/payments/${order.chargeId}`}>View in Stripe</a>
            </Typography>
            )}
          </div>

          <PayoutOrdersTable
            payout={order}
            isOrder
            stripeCompanyId={stripeId}
            currencySym={currencySym}
            timezone={timezone}
          />
        </>
      )}

      {/* Loading */}
      {!order && <Typography variant="subtitle1"> Loading...</Typography>}
    </div>

  );
};

Payment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  stripeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: state.companies.companyInfo.id,
  timezone: state.companies.companyInfo.timezone,
  stripeId: state.companies.companyInfo.stripeId,
});

export default connect(mapStateToProps, null)(Payment);
