import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ActionBar from '../../components/ActionBar';
import RunDbHealthCheckButton from '../../components/RunDbHealthCheckButton';
import RunCronJobSelectorAndButton from '../../components/RunCronJobSelectorAndButton';
import CreateCompanyDialog from '../../components/CreateCompanyDialog';
import ReauthoriseXeroButton from '../../components/ReauthoriseXeroButton';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import * as actions from '../../reducers/utilsReducer';
import { updateCompanyProduct, saveCompanyProducts } from '../../reducers/companiesReducer';
import { refreshLeagues } from '../../services/utilsServices';
import CreateNewDemoDialog from '../createDemo/CreateNewDemoDialog';
import { validateMembershipSubscriptions } from '../../services/membershipsServices';
import { ValidateMembershipSubscriptionsButton } from './ValidateMembershipSubscriptionsButton';

class Utils extends React.Component {
  componentDidMount() {
    const { doRequestXeroExchangeCode } = this.props;
    const url = window.location.href;
    if (url.includes('xero-auth')) {
      doRequestXeroExchangeCode(url);
    }
  }

  render() {
    const { companies, doUpdateCompanyProduct, doSaveCompanyProducts } = this.props;
    const { id, name, products } = companies.companyInfo;
    const localOrDemo = window.location.href.includes('manager-demo.pitchbooking.co') || window.location.href.includes('localhost:4999');

    const statuses = [
      { value: 'ENABLED', label: 'ENABLED', color: 'green' },
      { value: 'DISABLED', label: 'DISABLED', color: 'red' },
      { value: 'REQUESTED', label: 'REQUESTED', color: 'orange' },
      { value: 'HIDDEN', label: 'HIDDEN', color: 'grey' },
    ];

    return (
      <>
        <PitchbookingUserOnly>
          <ActionBar>
            <RunDbHealthCheckButton />
            <CreateCompanyDialog />
            <Button
              variant="contained"
              color="primary"
              onClick={() => refreshLeagues(id)}
            >
              Refresh Leagues
            </Button>
          </ActionBar>
          <ActionBar>
            <RunCronJobSelectorAndButton />
          </ActionBar>
          <ActionBar>
            <ReauthoriseXeroButton />
            <ValidateMembershipSubscriptionsButton />
          </ActionBar>
          <Card>
            <CardContent>
              <p>
                <b>
                  {name}
                  {' '}
                  Products
                </b>
                {' '}
                This is a list of products that are available to be enabled or disabled.
              </p>
              <div>
                {Object.entries(products).map(([key, value]) => (
                  <div key={key} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '10px', width: '140px' }}>{key}</p>
                    <select
                      value={value}
                      onChange={(e) => doUpdateCompanyProduct(key, e.target.value)}
                      style={{
                        color: statuses.find((status) => status.value === value).color,
                        fontWeight: 'bold',
                      }}
                    >
                      {statuses.map((status) => (
                        <option
                          key={status.value}
                          value={status.value}
                          style={{ color: status.color }}
                        >
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => doSaveCompanyProducts(id)}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </PitchbookingUserOnly>
        <ConditionallyVisible condition={localOrDemo}>
          <ActionBar>
            <CreateNewDemoDialog />
          </ActionBar>
        </ConditionallyVisible>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  const { isLoading } = users;
  return {
    users, isLoading, companies,
  };
}

Utils.propTypes = {
  doRequestXeroExchangeCode: PropTypes.func.isRequired,
  doUpdateCompanyProduct: PropTypes.func.isRequired,
  doSaveCompanyProducts: PropTypes.func.isRequired,
  companies: PropTypes.shape().isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  doRequestXeroExchangeCode: (code) => dispatch(actions.requestXeroExchangeCode(code)),
  doUpdateCompanyProduct: (id, value) => dispatch(updateCompanyProduct(id, value)),
  doSaveCompanyProducts: (id) => dispatch(saveCompanyProducts(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Utils);
